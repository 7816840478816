import { privateAxiosIntance, publicAxiosInstance } from "Configs/axios";
import { API_URLS, protectedRoutes } from "Configs/urls";

export const getEventFn = ({ search, page }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.event}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}
export const getpublicEventFn = () => {
    try {
        const response = publicAxiosInstance.get(`${API_URLS.event_api}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getEventByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.event}?event_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteEventByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.event}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postEventFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.event, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchEventFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.event, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}