import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik, validateYupSchema } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCertificateById, patchCertificatePlanFn, postCertificatePlanFn } from "Services/Admin/Profile";

export default function AddCertificate() {
  const { state } = useLocation();
  const { status } = useParams();

  const navigate = useNavigate();

  const { data: fetchedData } = useQuery({
    queryKey: ["get-category-by-id", state, (state === "update")],
    queryFn: () => getCertificateById(state),
    enabled: !!state && status === "update",
  });

  const { mutate: postCertificatePlan } = useMutation(postCertificatePlanFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Added New Category");
        navigate("/create-certificate-plan")
      }
    }
  })

  const { mutate: patchCertificatePlan } = useMutation(patchCertificatePlanFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Updated Category");
        navigate("/create-certificate-plan")
      }
      if (response.data?.response_code === 201) {
        toast.success(response.data?.message);
      }
    }
  })

  const data = fetchedData?.data?.data
  const formik = useFormik({
    initialValues: {
      type: data?.type || "",
      price: data?.price || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("type", values.type);
      formData.append("price", values.price);
      if (!!state && status === "update") {
        formData.append("certificate_plan_id", state);
        patchCertificatePlan(formData);
      } else {
        postCertificatePlan(formData);
      }
    },
  });

  const planOptions = [
    { label: "Yearly", value: 'Yearly' },
    /*  { label: "Monthly", value: 'Monthly' } */
    { label: "SixMonth", value: "SixMonth" }
  ]

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Type</p>
                    <Autocomplete
                      disablePortal
                      value={formik.values.type}
                      renderInput={(params) => <TextField {...params} />}
                      options={planOptions}
                      onChange={(e, o) => {
                        formik.setFieldValue("type", o.value)
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0 10px"
                        }
                      }}
                    />
                  </div>
                  <div>
                    <p className="p-1 text-sm font-semibold">Price</p>
                    <input
                      id="price"
                      placeholder="Price"
                      value={formik.values.price}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3">
                  <Button type="submit" variant="contained">
                    {state ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
