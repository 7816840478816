import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategoriesFn } from "Services/Admin/Category";
import { getSubCategoryByIdFn, patchSubCategoriesFn, postSubCategoriesFn } from "Services/Admin/SubCategory";

export default function AddSubCategory() {
  const { state } = useLocation();
  const { state: status } = useParams();
  const navigate = useNavigate();

  // Fetch existing subcategory data if updating
  const { data: fetchedData } = useQuery({
    queryKey: ["get-sub-category-by-id", state, (state === "update")],
    queryFn: () => getSubCategoryByIdFn(state),
    enabled: !!state && status === "update",
  });

  // Fetch all categories for the dropdown
  const { data: categoryData } = useQuery({
    queryKey: ["get-categorydata"],
    queryFn: getCategoriesFn,
  });

  // Mutation for adding a new subcategory
  const { mutate: postSubCategories } = useMutation(postSubCategoriesFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Added New Sub Category");
        navigate("/sub-category");
      }
    },
  });

  // Mutation for updating an existing subcategory
  const { mutate: patchSubCategories } = useMutation(patchSubCategoriesFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Updated Sub Category");
        navigate("/sub-category");
      }
      if (response.data?.response_code === 201) {
        toast.success(response.data?.message);
      }
    },
  });

  const data = fetchedData?.data?.data;

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      images: null,
      category: data?.category || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      if (!values.category) return toast.error("Category is required.")
      formData.append("name", values.name);
      formData.append("images", values.images);
      formData.append("category", values.category);

      if (!!state && status === "update") {
        formData.append("subcategory_id", state);
        if (!values.images) formData.delete("images");
        patchSubCategories(formData);
      } else {
        if (!values.images) return toast.error("Image is required.")
        postSubCategories(formData);
      }
    },
  });

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md">
              <div className="flex flex-col gap-5">
                {/* Category Selection */}
                <div className="w-full pl-3 mt-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Category</p>
                    <select
                      id="category"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    >
                      <option>Select category</option>
                      {categoryData?.data?.data?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {/* Subcategory Name Input */}
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Sub Category Name</p>
                    <input
                      id="name"
                      placeholder="Enter name"
                      value={formik.values.name}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                {/* Image Upload */}
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Image</p>
                    <input
                      id="images"
                      type="file"
                      placeholder="Enter image"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(event) =>
                        formik.setFieldValue("images", event.target.files[0])
                      }
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="w-full pl-3">
                  <Button type="submit" variant="contained">
                    {state ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
