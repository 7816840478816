import { useQuery } from "react-query";
import { getDashboardFn } from "Services/Admin/Dashboard";

const Dashboard = () => {
    const { data: getDashboard } = useQuery({
        queryKey: ["get-dasboard"],
        queryFn: getDashboardFn
    })

    const data = getDashboard?.data?.data

    return (
        <div className="p-4">
            <p className="my-3 text-5xl text-center text-blue-900 underline">Dashboard</p>
            <div className="grid gap-4 grid-cols-4 mt-8">
                {data
                    && Object.keys(data).map((key) => (
                        <div key={key} className="w-full bg-blue-100 text-center py-8 rounded-lg">
                            <p>{data[key]}</p>
                            <h3 className="font-medium text-xl">{key}</h3>
                        </div>
                    ))

                }
            </div>
        </div>
    )
}

export default Dashboard;