import { createContext, useContext, useState } from "react";

const DonationContext = createContext(null);

export const DonationContextProvder = ({ children }) => {
    const [openModal, setOpenModal] = useState(false);

    const handleModal = () => setOpenModal(prev => !prev);

    return (
        <DonationContext.Provider value={{ openModal, handleModal }}>
            {children}
        </DonationContext.Provider>
    )
}

const useDonationContext = () => {
    return useContext(DonationContext);
}
export default useDonationContext;