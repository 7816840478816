import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getNewsFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.news);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getNewsByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.news}?news_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteNewsByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.news}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postNewsFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.news, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchNewsFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.news, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}