import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getCreatePaymentLinkFn = ({ search, page }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.create_payment}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const postCreatePaymentLinkFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(`${protectedRoutes.create_payment}`, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}