import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getEngineersFn, postEngineerApprovalStatusFn, postEngineerStatusFn } from 'Services/Admin/Engineers';
import RemarkModal from './RemarkModal';
import CertificateGenerate from 'Components/Common/CertificateGenerate';
import { imageFormat } from 'Configs/axios';

const Engineers = () => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [approved, setApproved] = useState(null);

    const { data: getEngineer, refetch } = useQuery({
        queryKey: ["get-engineers", page, search],
        queryFn: () => getEngineersFn({ search, page }),
        keepPreviousData: true,
    });

    const { mutate: postEngineerStatus } = useMutation(postEngineerStatusFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                refetch();
                toast.success("Status Updated");
            }
        }
    });

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleStatus = (id) => {
        const formData = new FormData();
        formData.append("engineer_id", id);
        postEngineerStatus(formData);
    };

    const handleCertificateClick = (certificate) => {
        setSelectedCertificate(certificate);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedCertificate(null);
    };

    useEffect(() => {
        if (getEngineer?.data) {
            setTotalPage(getEngineer?.data?.total_pages || 1);
        }
    }, [getEngineer]);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Engineers</p>
            <div className="flex items-center my-2 mt-4">
                <span className="flex gap-3">
                    <input
                        className="block px-2 py-2 pr-3 mx-3 bg-white border rounded-md shadow-sm placeholder:italic placeholder:text-slate-400 w-80 border-slate-300 focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                        placeholder="Search for anything..."
                        type="text"
                        name="search"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </span>
                {getEngineer?.data?.total_engineer && <span >
                    Total Engineers & Architects: {getEngineer?.data?.total_engineer}
                </span>
                }
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Member ID
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Profile Photo
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                ER/AR
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Name
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Father Name
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Address
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Email
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Mobile
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                DOB
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Gender
                            </TableCell>
                            {/* <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Country
                            </TableCell> */}
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                State
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                District
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Block
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Status
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Approval Status
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Paid Status
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Created AT
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Certificate
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getEngineer?.data?.data.map((row) =>
                            <Row
                                row={row}
                                handleCertificateClick={handleCertificateClick}
                                handleStatus={handleStatus}
                                refetch={refetch}
                                setApproved={setApproved}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>

            {/* Certificate Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    Certificate
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {selectedCertificate ? (
                        <img src={selectedCertificate} alt="Certificate" className='w-1/2 mx-auto border' />
                    ) : (
                        <p>No certificate available.</p>
                    )}
                </DialogContent>
            </Dialog>
            <CertificateGenerate data={approved} setApproved={setApproved} refetch={refetch} />
        </section>
    )
}

const Row = ({ row, handleStatus, handleCertificateClick, refetch, setApproved }) => {
    const createdDate = new Date(row?.is_created).toLocaleDateString();
    const [select, setSelect] = useState(row?.approval_status || "");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { mutate: prostEngineerApprovalStatus } = useMutation(postEngineerApprovalStatusFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("Status Updated");
                refetch();
                setLoading(false);
            }
        },
        onError: () => {
            toast.error("Failed to update status");
            setLoading(false);
        },
    });

    const handleChange = (e) => {
        const newStatus = e.target.value;
        if (newStatus === "Rejected") {
            setIsModalOpen(true);
        } else if (newStatus === "Pending") {
            setSelect(newStatus);
            const formData = new FormData();
            formData.append("status", newStatus);
            formData.append("engineer_id", row.id);
            setLoading(true);
            prostEngineerApprovalStatus(formData);
        } else {
            setApproved(row);
        }
    };

    const handleRemarkSubmit = (remark) => {
        setLoading(true);
        setSelect("Rejected");
        const formData = new FormData();
        formData.append("status", "Rejected");
        formData.append("engineer_id", row.id);
        formData.append("remark", remark);
        prostEngineerApprovalStatus(formData);
        setIsModalOpen(false);
    };

    return (
        <TableRow key={row?.id} className="!text-slate-700">
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.member_id || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {!row?.image && "--"}
                {row?.image && <img
                    src={imageFormat(row?.image)}
                    alt={row?.name}
                    className='cursor-pointer'
                    onClick={() => handleCertificateClick(imageFormat(row?.image))}
                />}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.prefix || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.name || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.fathername || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.address || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.email || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.mobile || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.dob || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.gender || "--"}
            </TableCell>
            {/* <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.country || "--"}
            </TableCell> */}
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.state || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.district || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.block || "--"}
            </TableCell>
            <TableCell
                className="!text-center !text-[12px]"
                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}
            >
                <Switch
                    checked={row?.is_activate}
                    onChange={() => handleStatus(row.id)}
                    color="primary"
                />
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {loading ? <span>Processing...</span> : row?.approval_status !== "Approved" ? (
                    <select className='border border-black outline-none rounded-md w-[100px] py-2' value={select} onChange={handleChange}>
                        <option value={"Pending"}>Pending</option>
                        <option value={"Approved"}>Approved</option>
                        <option value={"Rejected"}>Rejected</option>
                    </select>
                ) : (
                    <span className='font-bold text-green-700'>Approved</span>
                )}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {row?.buy_certificate?.[0]?.status || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                {createdDate || "--"}
            </TableCell>
            <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                {row?.certificate ? (
                    <Button
                        variant='contained'
                        className='text-white bg-blue-800 cursor-pointer'
                        onClick={() => handleCertificateClick(imageFormat(row?.certificate))}
                    >
                        Open
                    </Button>
                ) : "--"}
            </TableCell>
            <RemarkModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleRemarkSubmit}
            />
        </TableRow>
    )
}


export default Engineers;