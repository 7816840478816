import AdminHeader from "Components/Admin/common/AdminHeader";
import Sidebar from "Components/Admin/common/Sidebar";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
    return (
        <div className="grid grid-cols-[300px_1fr] h-screen w-screen overflow-hidden">
            <Sidebar />
            <div className="flex flex-col w-full gap-2 p-2 bg-gray-100 overflow-x-auto">
                <AdminHeader />
                <div className="flex flex-col overflow-y-auto bg-white rounded-lg text-black">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
