import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getEngineersFn = ({ search, page }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.enginneer}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getEngineersListFn = () => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.enginneer}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const postEngineerStatusFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(`${protectedRoutes.engineer_status}`, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const postEngineerApprovalStatusFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(`${protectedRoutes.engineer_approval}`, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}