import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getPaymentHistoryFn } from 'Services/Admin/PaymentHistory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useCopied from 'hooks/useCopied';
import { CopyAllOutlined } from '@mui/icons-material';

const PaymentHistory = () => {
    const { copy } = useCopied();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const { data: getPaymentHistoryResponse } = useQuery({
        queryKey: ["get-payment-history", !!page, !!search],
        queryFn: () => getPaymentHistoryFn({ search, page })
    })

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (getPaymentHistoryResponse?.data) {
            setTotalPage(getPaymentHistoryResponse.data.total_pages || 1);
        }
    }, [getPaymentHistoryResponse]);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Payment History</p>
            <div className="flex items-center justify-between my-2 mt-4">
                <span className="flex gap-3">
                    <input
                        className="block px-2 py-2 pr-3 mx-3 bg-white border rounded-md shadow-sm placeholder:italic placeholder:text-slate-400 w-80 border-slate-300 focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                        placeholder="Search for anything..."
                        type="text"
                        name="search"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </span>
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                ID
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Payment Id
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Payment Amount
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Payment For
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Expiry Time
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Payment Link
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Created Date
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                                colSpan={2}
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPaymentHistoryResponse?.data?.data.map((row) => {
                            const expiryTime = new Date(row?.expiry_time).toLocaleTimeString();
                            const createdDate = new Date(row?.created_date).toLocaleDateString();

                            return (
                                <TableRow key={row?.id} className="!text-slate-700">
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.id}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_id}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        ₹{row?.payment_amount}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_for}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {expiryTime}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        <Link to={row?.payment_link} className='text-green-700'>Link</Link>
                                        <span
                                            onClick={() => copy(row?.payment_link)}
                                            className='ml-4 text-blue-700 cursor-pointer'
                                        >
                                            <CopyAllOutlined fontSize='smaill' />
                                        </span>
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {createdDate}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                                        {row?.status}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                                        {
                                            row?.status === "Paid" && <span className='text-sm font-bold text-green-800'><CheckCircleIcon /></span>
                                        }
                                        {
                                            row?.status === "Unpaid" && <Link to={row?.payment_link} className='px-4 py-2 text-white bg-blue-800 rounded-sm'>
                                                Pay Now
                                            </Link>
                                        }
                                        {
                                            row?.status === "Expired" && <span className='px-4 py-2 text-white bg-red-600 rounded-sm'>Expired</span>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>
        </section>
    )
}

export default PaymentHistory;