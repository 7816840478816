import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getContactUsFn, postContactUstatusFn } from 'Services/Admin/ContactUs';

const AdminContactUs = () => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const { data: getContactUs, refetch } = useQuery({
        queryKey: ["get-contact-us", page, search],
        queryFn: () => getContactUsFn({ search, page }),
        keepPreviousData: true, // Keeps old data while fetching new data
        staleTime: 5000 // Adjust this to fit your use case
    });

    const { mutate: postContactUstatus } = useMutation(postContactUstatusFn, {
        onSuccess: () => {
            refetch();
            toast.success("Status Updated");
        },
        onError: () => {
            toast.error("Failed to update status.");
        }
    });

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleStatus = (id) => {
        const formData = new FormData();
        formData.append("contactus_id", id);
        postContactUstatus(formData);
    };

    const handleCertificateClick = (certificate) => {
        setSelectedCertificate(certificate);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedCertificate(null);
    };

    useEffect(() => {
        if (getContactUs?.data) {
            setTotalPage(getContactUs?.data?.total_pages || 1);
        }
    }, [getContactUs]);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Contact Us</p>
            <div className="flex items-center my-2 mt-4">
                <span className="flex gap-3">
                    <input
                        className="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                        placeholder="Search for anything..."
                        type="text"
                        name="search"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    />
                </span>
                {getContactUs?.data?.total_engineer && <span>
                    Total Engineers: {getContactUs?.data?.total_engineer}
                </span>}
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                ID
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Name
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Mobile
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Email
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Status
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getContactUs?.data?.data?.map((row) => (
                            <TableRow key={row?.id} className="!text-slate-700">
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.id || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.name || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.mobile || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.email || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    <Switch
                                        checked={row?.status}
                                        onChange={() => handleStatus(row.id)}
                                        color="primary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>

            {/* Certificate Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>
                    Certificate
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {selectedCertificate ? (
                        <img src={selectedCertificate} alt="Certificate" style={{ width: '100%' }} />
                    ) : (
                        <p>No certificate available.</p>
                    )}
                </DialogContent>
            </Dialog>
        </section>
    );
}

export default AdminContactUs;
