import { imageFormat, publicAxiosInstance } from 'Configs/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Blogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const blogsFn = async () => {
    const response = await publicAxiosInstance.get('/blog/blog-api/');
    setData(response.data.data);
    console.log(response.data);
    setLoading(false);
  };

  useEffect(() => {
    blogsFn();
  }, []);

  const removeHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+>/gi, '');
  };

  const truncateContent = (content, wordLimit) => {
    const plainText = removeHtmlTags(content);
    const words = plainText.split(/\s+/);
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return plainText;
  };

  const handleReadMoreClick = (id) => {
    navigate(`/view-blogs/${id}`);
  };

  return (
    <div className="bg-white flex justify-center items-center">
      <div className="container bg-white p-4 text-black">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {data.map((detail) => (
            <div key={detail.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-xl">
              <div className="w-full h-40 overflow-hidden">
                <img
                  src={imageFormat(detail.images)}
                  alt={detail.title}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="p-4">
                <p className="text-black text-lg font-semibold mb-2 truncate">{detail.title}</p>
                <p
                  dangerouslySetInnerHTML={{ __html: truncateContent(detail.Content, 20) }}
                  className="text-sm text-gray-700 line-clamp-3"
                />
                <button
                  onClick={() => handleReadMoreClick(detail.id)}
                  className="mt-4 text-blue-500 hover:underline"
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
