import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Facebook, WhatsApp, Menu as MenuIcon } from '@mui/icons-material'
import { Drawer, IconButton, Divider } from '@mui/material'
import Marquee from 'react-fast-marquee'
import logo from "./logo.jpeg"
import logo_translate from 'assets/logo-transparent.png'
import axios from 'axios'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useAuth from 'hooks/useAuth'
import { publicAxiosInstance } from 'Configs/axios'
import { API_URLS } from 'Configs/urls'

/**
 * Header component.
 * Represents the header section of the application.
 * @param {Object} props - Component props.
 * @param {function} props.setMode - Function to set the theme mode.
 * @param {'dark' | 'light'} props.mode - Current theme mode ('dark' or 'light').
 * @returns {JSX.Element} Header component JSX.
 */
const Header = () => {
  const { isTokenExist, userType } = useAuth()
  const [data, setData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const newsFn = async () => {
    const response = await publicAxiosInstance.get(API_URLS.new_api);
    setData(response.data.data);
  };

  useEffect(() => {
    newsFn();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className='relative sticky top-0 z-50 bg-white'>
      {/* Top bar with news and social icons */}
      <div className="flex bg-blue-900 md:h-[68px] h-[50px] font-bold justify-center items-center">
        <div className="container flex gap-3">
          <p className="bg-[#f54337] h-14 w-[150px] pt-3 text-center text-white font-bold md:block hidden">
            FLASH NEWS :
          </p>
          <Marquee className="text-white flex !gap-5 flex-1">
            <div className="flex !gap-5">
              <img src="https://www.sresindia.org/images/new-icon.png" alt="" />
              {data?.map((news, index) => (
                <Link to={`/news/${news.id}`} key={index}>{news.title}</Link>
              ))}
            </div>
          </Marquee>
          <div className="flex items-center justify-center gap-2 text-white lg:gap-3">
            <Facebook />
            <a href="https://wa.me/919039131372" target="_blank" rel="noopener noreferrer" className='flex items-center font-semibold text-white'>
              <WhatsApp className='lg:mr-2' />
            </a>
            {!isTokenExist
              ? (
                <Link
                  to="/login"
                  className="flex items-center justify-center float-left p-2 text-base text-white cursor-pointer lg:p-3 lg:text-xl "
                >
                  LOGIN
                </Link>
              ) : userType === "Engineer" ? (
                <Link to={"/profile"} className='cursor-pointer'><AccountCircleIcon /> </Link>
              ) : (
                <Link to={"/blogs"} className='cursor-pointer'><AccountCircleIcon /> </Link>
              )
            }
          </div>
        </div>
      </div>

      {/* Main header with logo and menu icon */}
      <div className="flex bg-white max-w-[1540px] mx-auto font-bold items-center h-[60px]">
        {/* For smaller screens: Logo and Menu Icon */}
        <div className="flex items-center justify-between w-full h-full lg:hidden">
          <Link to="/" className='!relative h-full'>
            <div className='ml-4 bg-white rounded-full shadow-2xl w-28 h-28'>

            </div>
            <img src={logo_translate} alt='' className='!absolute rounded-full top-2.5 left-6  w-28' />
          </Link>
          <IconButton
            color="inherit"
            aria-label="menu"
            edge="start"
            onClick={handleDrawerToggle}
            className="ml-auto"
          >
            <MenuIcon style={{ color: 'black' }} />
          </IconButton>
        </div>

        {/* For medium and larger screens: Logo and Menu Items */}
        <div className="items-center justify-end hidden w-full lg:flex">
          {/* <Link to="/">
            <img src={logo} alt='' className='' />
          </Link> */}
          <Link to="/" className='absolute left-[10%] scale-150 top-24 h-28 w-28'>
            <div className='w-full h-full bg-white rounded-full shadow-2xl'>

            </div>
            <img
              src={logo_translate}
              alt=''
              className='h-32 w-32 absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[60%] object-cover'
            />

          </Link>
          <div className="flex items-center gap-6">
            <Link to="/" className="block p-3 text-black">
              HOME
            </Link>
            <Link to="/team" className="block p-3 text-black">
              OUR TEAM
            </Link>
            <Link to="/about-us" className="block p-3 text-black">
              ABOUT US
            </Link>
            <Link to="/view-gallery" className="block p-3 text-black">
              GALLERY
            </Link>
            <Link to="/view-blogs" className="block p-3 text-black">
              BLOG
            </Link>
            <Link to="/newsevent" className="block p-3 text-black">
              NEWS & EVENTS
            </Link>
            <Link to="/contact" className="block p-3 text-black">
              CONTACT  US
            </Link>
          </div>
        </div>
      </div>

      {/* Drawer for mobile view */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <div className="!bg-white !text-black w-60 h-screen p-4">
          <Link to="/" className="block p-3 hover:text-black">
            HOME
          </Link>
          <Link to="/team" className="block p-3 hover:text-black">
            OUR TEAM
          </Link>
          <Link to="/about-us" className="block p-3 hover:text-black">
            ABOUT US
          </Link>
          <Link to="/view-gallery" className="block p-3 hover:text-black">
            GALLERY
          </Link>
          <Link to="/blogs" className="block p-3 hover:text-black">
            BLOG
          </Link>
          <Link to="/newsevent" className="block p-3 hover:text-black">
            NEWS & EVENTS
          </Link>
          <Link to="/contact" className="block p-3 text-black">
            CONTACT US
          </Link>
          <Divider />
          <Link
            to="/login"
            className="block p-3 hover:text-black"
          >
            LOGIN
          </Link>

        </div>
      </Drawer>
    </div>
  );
}

export default Header;
