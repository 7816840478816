import { imageFormat, publicAxiosInstance } from 'Configs/axios';
import { API_URLS } from 'Configs/urls';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { getpublicEventFn } from 'Services/Admin/Event';

const EventDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const eventFn = async () => {
        try {
            const response = await publicAxiosInstance.get(`${API_URLS.event_api}?event_id=${id}`, {
            });
            setData(response?.data?.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const { data: eventDetails } = useQuery({
        queryFn: getpublicEventFn,
        queryKey: ['get-event-fn']
    })

    useEffect(() => {
        eventFn();
    }, [id]);

    if (loading) {
        return <div className="flex items-center justify-center h-full">Loading...</div>;
    }

    if (!data) {
        return <div className="flex items-center justify-center h-full">No data found</div>;
    }

    return (
        <div className="bg-white text-black">
            <div className="bg-white flex justify-center items-center pt-10">
                <div className="flex flex-col bg-white p-6 gap-10 lg:w-[60%] w-full text-black">
                    {data.image && (
                        <img
                            src={imageFormat(data.image)}
                            alt={data.title}
                            className="w-full md:h-[60vh] h-[30vh] object-contain"
                        />
                    )}
                    <div className="flex flex-col">
                        <h1 className="text-3xl font-bold">{data.title}</h1>
                        <p className="text-sm text-gray-500 mt-2">
                            {data?.created_at}
                        </p>
                        <p className="text-sm mt-2">{data?.sub_title}</p>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.content }}
                            className="text-gray-800 mt-4 flex flex-col gap-5 lg:p-0 p-2"
                        ></div>
                    </div>
                </div>
            </div>
            <div className='max-w-[1000px] mx-auto px-6'>
                <h3 className='font-bold text-2xl mb-3'>Recent Events</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                    {eventDetails?.data?.data.slice(0, 8).map((detail) => (
                        <Link to={`/event/${detail.id}`} className="text-black relative h-64 border overflow-hidden rounded-lg shadow-lg" key={detail.id}>
                            <img
                                src={imageFormat(detail.image)}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                            <p className='text-center bottom-0 bg-white absolute w-full py-3 px-2 overflow-hidden whitespace-nowrap text-ellipsis'>{detail.title}</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EventDetails;
