import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCategoryByIdFn, patchCategoriesFn, postCategoriesFn } from "Services/Admin/Category";

export default function AddCategory() {
  const { state } = useLocation();
  const { state: status } = useParams();

  const navigate = useNavigate();

  const { data: fetchedData } = useQuery({
    queryKey: ["get-category-by-id", state, (state === "update")],
    queryFn: () => getCategoryByIdFn(state),
    enabled: !!state && status === "update",
  });

  const { mutate: postCategories } = useMutation(postCategoriesFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Added New Category");
        navigate("/category")
      }
    }
  })

  const { mutate: patchCategories } = useMutation(patchCategoriesFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Updated Category");
        navigate("/category")
      }
      if (response.data?.response_code === 201) {
        toast.success(response.data?.message);
      }
    }
  })

  const data = fetchedData?.data?.data
  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      images: null,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("images", values.images);
      if (!!state && status === "update") {
        formData.append("category_id", state);
        if (!values.images) formData.delete("images");
        patchCategories(formData);
      } else {
        if (!values.images) return toast.error("Image is required.")
        postCategories(formData);
      }
    },
  });

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Category Name</p>
                    <input
                      id="name"
                      placeholder="Enter name"
                      value={formik.values.name}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Image</p>
                    <input
                      id="images"
                      type="file"
                      placeholder="Enter image"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(event) =>
                        formik.setFieldValue("images", event.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="w-full pl-3">
                  <Button type="submit" variant="contained">
                    {state ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
