import { Delete, Update } from '@mui/icons-material';
import { Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { imageFormat } from 'Configs/axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteEventByIdFn, getEventFn } from 'Services/Admin/Event';

const Event = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");

    const { data: getEvent, refetch } = useQuery({
        queryKey: ["get-events", page, search],
        queryFn: () => getEventFn({ search, page }),
        keepPreviousData: true, // Keeps old data while fetching new data
        staleTime: 5000 // Adjust this to fit your use case
    });

    const { mutate: deleteEvent } = useMutation(deleteEventByIdFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                refetch();
                toast.success("Deleted Successfully.");
            }
        },
        onError: () => {
            toast.error("Failed to delete event.");
        }
    });

    const handleChange = (event, value) => {
        setPage(value);
    };

    const handleDelete = (id) => {
        const formData = new FormData();
        formData.append("event_id", id);
        deleteEvent(formData);
    };

    useEffect(() => {
        if (getEvent?.data) {
            setPage(getEvent.data.current_page); // Make sure the API returns the current page
        }
    }, [getEvent]);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Event</p>
            <div className="flex items-center justify-between my-2 mt-4">
                <div className="flex items-center my-2 mt-4">
                    <span className="flex gap-3">
                        <input
                            className="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                            placeholder="Search for anything..."
                            type="text"
                            name="search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </span>
                    {getEvent?.data?.total_payment_amount && <span className='text-blue-800'>
                        <b>Total Received:</b> ₹{getEvent?.data?.total_payment_amount}
                    </span>}
                </div>
                <Button variant='contained' onClick={() => navigate("/event/add")}>
                    Add
                </Button>
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                ID
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Title
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Image
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Location
                            </TableCell>
                            <TableCell
                                style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                className="!text-center !text-gray-800 !font-bold"
                            >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getEvent?.data?.data?.map((row) => (
                            <TableRow key={row?.id} className="!text-slate-700">
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.id || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.title || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.image ? <img src={imageFormat(row?.image)} className='h-10 mx-auto' alt={row?.title} /> : "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    {row?.location || "--"}
                                </TableCell>
                                <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                    <Update className='cursor-pointer text-blue-800' onClick={() => navigate("/event/update", { state: row.id })} />
                                    <Delete className='cursor-pointer text-red-500' onClick={() => handleDelete(row.id)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={getEvent?.data?.total_pages || 1} // Adjust this if needed
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>
        </section>
    );
}

export default Event;
