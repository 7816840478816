import React from 'react'

const AboutUs = () => {
    const images = [
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic2.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic4.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic3.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic6.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic7.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic8.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic9.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic12.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic11.jpeg"
        },
        {
            image: "https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic13.jpeg"
        },
    ]
    return (
        <div className='text-black max-w-[1500px] mx-auto py-16 px-4 '>
            <div className='flex flex-col items-center gap-4 my-16 md:flex-row'>
                <div className='flex flex-col gap-2 w-full md:w-[60%] lg:w-[50%]'>
                    <h4 className="text-xl font-bold lg:text-4xl md:2xl ">About Us</h4>
                    <p>HubSpot's company and culture are a lot like our product. They're crafted, not cobbled, for a delightful experience.</p>
                </div>
                <div className='w-full md:w-[40%] lg:w-[50%] '>
                    <img src="https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic1.jpeg" alt="" className='w-full mx-auto shadow-lg lg:w-2/3' />
                </div>
            </div>
            <div className='flex flex-col items-center gap-4 my-16 md:flex-row-reverse'>
                <div className='flex flex-col gap-2 w-full md:w-[60%] lg:w-[50%]'>
                    <h4 className="text-xl font-bold lg:text-4xl md:2xl">Our Mission</h4>
                    <p>We believe not just in growing bigger, but in growing better. And growing better means aligning the success of your own business with the success of your customers. Win-win!</p>
                </div>
                <div className='w-full md:w-[40%] lg:w-[50%]'>
                    <img src="https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic10.jpeg" alt="" className='w-full mx-auto shadow-lg lg:w-2/3' />
                </div>
            </div>
            <div className='flex flex-col items-center gap-4 my-16 md:flex-row'>
                <div className='flex flex-col gap-2 w-full md:w-[60%] lg:w-[50%] '>
                    <h4 className="text-xl font-bold lg:text-4xl md:2xl">Vision</h4>
                    <p>To be a leading provider of quality professional development programmes, research and information service in the field of entrepreneurship promotion/ motivation and development and management of micro small and medium enterprises.</p>
                </div>
                <div className='w-full md:w-[40%] lg:w-[50%]'>
                    <img src="https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/pic5.jpeg" alt="" className='w-full mx-auto shadow-lg lg:w-2/3' />
                </div>
            </div>
            <div className='flex flex-col items-center justify-center'>
                <p className='text-xl font-bold underline lg:text-4xl md:2xl'>Department We Are Affiliated</p>
                <div className='flex flex-col gap-10 pt-3 md:pt-5 md:flex-row'>
                    <div className='border-4 border-gray-200 shadow-lg'>
                        <img src='https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/image.png' alt='' className='md:h-[30vh] h-[25vh] p-2'></img>
                        <p className='p-2 text-base font-bold text-center '>वित्त विभाग उत्तर प्रदेश सरकार</p>
                    </div>
                    <div className='border-4 border-gray-200 shadow-lg' >
                        <img src='http://localhost:3000/static/media/Uttar-Pradesh-Panchayati-Raj-Department.2688a5bf40d24c08ae09.jpg' alt='' className='p-2 md:h-[30vh] h-[25vh] '></img>
                        <p className='p-2 text-base font-bold text-center '>पंचायती राज</p>
                    </div>
                </div>
            </div>
            <div className='grid gap-5 pt-5 lg:grid-cols-4 md:grid-cols-2'>
                {images.map((img) => (
                    <div className='border-4 border-gray-200'>
                        <img src={img.image} alt='' className='h-56 p-2 transition-transform transform w-96 hover:scale-105' />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AboutUs