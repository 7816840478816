import { Button } from "@mui/material";
import { imageFormat } from "Configs/axios";
import { useMutation, useQuery } from "react-query";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBannerByIdFn, getBannerFn } from "Services/Admin/Banner";

const Banner = () => {
    const navigate = useNavigate();

    const { data: getBannerResponse, refetch } = useQuery({
        queryKey: "get-banner",
        queryFn: getBannerFn
    })

    const { mutate: deleteBanner } = useMutation(deleteBannerByIdFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("Deleted Successfully");
                refetch();
            }
        }
    })

    const deleteHandle = (id) => {
        const formData = new FormData();
        formData.append("banner_id", id);
        deleteBanner(formData);
    }

    return (
        <>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Banner</p>
            <div className="flex justify-between item-center w-[100%]">
                <NavLink to="/banner/add">
                    <div className="text-xl font-bold !text-gray-600 p-3">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid grid-cols-2">
                {getBannerResponse?.data?.data?.map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className=" grid grid-cols-3 border border-gray-300 drop-shadow-2xl mx-auto rounded w-[98%] my-3 relative"
                        >
                            <div className="flex flex-col items-center justify-center">
                                <p className="px-4 py-4 text-2xl text-black">{item.banner_section}</p>
                                <Link className="text-green-600 underline" to={item.link}>Link</Link>
                                <div className="bg-red-500 text-white text-sm px-4 py-2 rounded-lg absolute right-2 top-2">{item.offer_tag}</div>
                            </div>
                            <div className="flex flex-col items-center justify-center w-full">
                                <img
                                    key={item.images}
                                    src={`${imageFormat(item.image)}`}
                                    alt="img"
                                    className="w-40 h-40"
                                />
                            </div>
                            <div className="flex items-center justify-center w-full p-5">
                                <div className="px-5 mt-32 ">
                                    <Button
                                        onClick={() => navigate("/banner/update", { state: item.id })}
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-32 ">
                                    <Button
                                        onClick={() => deleteHandle(item.id)}
                                        variant="outlined">
                                        Delete
                                    </Button>
                                </div>
                            </div >
                        </div>
                    );
                })
                }
            </div>
        </>
    );
};

export default Banner;

