import { privateAxiosIntance, publicAxiosInstance } from 'Configs/axios';
import { API_URLS, protectedRoutes } from 'Configs/urls';


export const giveDonationFn = async (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.give_donation, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getDonationListFn = async ({ page, search }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.donation_list}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}