import Router from 'Router'
import 'index.css'
import ReactDOM from 'react-dom/client'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthContextProvider from 'context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { DonationContextProvder } from 'context/useDonationContext';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <BrowserRouter>
        <AuthContextProvider>
            <DonationContextProvder>
                <Router />
            </DonationContextProvder>
        </AuthContextProvider>
    </BrowserRouter>
)
