import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getPaymentHistoryFn = ({ search, page }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.paymentHistory}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}