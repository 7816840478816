import { Button } from "@mui/material";
import { imageFormat } from "Configs/axios";
import { useMutation, useQuery } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBlogByIdFn, getBlogFn } from "Services/Admin/Blog";

const AdminBlog = () => {
    const navigate = useNavigate();

    const { data: getBlogResponse, refetch } = useQuery({
        queryKey: "get-blogs",
        queryFn: getBlogFn
    })

    const { mutate: deleteBlog } = useMutation(deleteBlogByIdFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("Deleted Successfully");
                refetch();
            }
        }
    })

    const deleteHandle = (id) => {
        const formData = new FormData();
        formData.append("blog_id", id);
        deleteBlog(formData);
    }

    return (
        <>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Blog</p>
            <div className="flex justify-between item-center w-[100%]">
                <NavLink to="/blogs/add">
                    <div className="text-xl font-bold !text-gray-600 p-3">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid grid-cols-2">
                {getBlogResponse?.data?.data?.map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className=" grid grid-cols-3 border border-gray-300 drop-shadow-2xl mx-auto rounded w-[98%] my-3"
                        >
                            <div className="flex flex-col items-center justify-center">
                                <p className="px-4 py-4 text-2xl text-black">{item.title}</p>
                            </div>
                            <div className="flex flex-col items-center justify-center w-full">
                                <img
                                    key={item.images}
                                    src={`${imageFormat(item.images)}`}
                                    alt="img"
                                    className="w-40 h-40"
                                />
                            </div>
                            <div className="flex items-center justify-center w-full p-5">
                                <div className="px-5 mt-32 ">
                                    <Button
                                        onClick={() => navigate("/blogs/update", { state: item.id })}
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-32 ">
                                    <Button
                                        onClick={() => deleteHandle(item.id)}
                                        variant="outlined">
                                        Delete
                                    </Button>
                                </div>
                            </div >
                        </div>
                    );
                })
                }
            </div>
        </>
    );
};

export default AdminBlog;

