
import LogIn from 'Authentication/LogIn'
import NotFound from 'NotFound'
import AboutUs from 'Pages/AboutUs'
import Banner from 'Pages/Admin/Banner'
import AddBanner from 'Pages/Admin/Banner/AddBanner'
import { default as AdminBlog, default as Blog } from 'Pages/Admin/Blog'
import AddBlog from 'Pages/Admin/Blog/AddBlog'
import Category from 'Pages/Admin/Category'
import AddCategory from 'Pages/Admin/Category/AddCategory'
import AdminContactUs from 'Pages/Admin/ContactUs'
import CreateCertificatePlan from 'Pages/Admin/CreateCertificatePlan'
import AddCertificate from 'Pages/Admin/CreateCertificatePlan/AddCertificate'
import CreatePaymentLink from 'Pages/Admin/CreatePaymentLink'
import AddPaymentLink from 'Pages/Admin/CreatePaymentLink/AddPaymentLink'
import Dashbaord from 'Pages/Admin/Dashboard'
import DonationList from 'Pages/Admin/DonationList'
import Engineers from 'Pages/Admin/Engineers'
import Event from 'Pages/Admin/Event'
import AddEvent from 'Pages/Admin/Event/AddEvent'
import AdminGallery from 'Pages/Admin/Gallery'
import AddGallery from 'Pages/Admin/Gallery/AddGallery'
import GalleryImage from 'Pages/Admin/GalleryImage'
import AddGalleryImage from 'Pages/Admin/GalleryImage/AddGalleryImage'
import News from 'Pages/Admin/News'
import AddNews from 'Pages/Admin/News/AddNews'
import OurMembers from 'Pages/Admin/OurMembers'
import Slider from 'Pages/Admin/Slider'
import AddSlider from 'Pages/Admin/Slider/AddSlider'
import SubCategory from 'Pages/Admin/SubCategory'
import AddSubCategory from 'Pages/Admin/SubCategory/AddSubCategory'
import Blogs from 'Pages/Blogs'
import BlogsDetails from 'Pages/Blogs/blogsdetails'
import ContactUs from 'Pages/ContactUs'
import EngineerDonationList from 'Pages/Engineer/DonestionList'
import PaymentHistory from 'Pages/Engineer/PaymentHistory'
import Profile from 'Pages/Engineer/Profile'
import Gallery from 'Pages/Gallery'
import Home from 'Pages/Home'
import NewsEvents from 'Pages/NewsEvents'
import EventDetails from 'Pages/NewsEvents/EventDetails/index.js'
import NewsDetails from 'Pages/NewsEvents/NewsDetails'
import Team from 'Pages/Team'

/**
 * Array of route objects defining the routes for the application.
 * @type {Array<{
 *   id: number,
 *   path: string,
 *   element: JSX.Element,
 *   title: string,
 *   status: boolean
 * }>}
 */
const routes = [
  {
    id: 0,
    path: '*',
    element: <NotFound />,
    title: '404 Not Found',
    status: true,
  },
  {
    id: 1,
    path: '/',
    element: <Home />,
    title: 'Home',
    status: true,
  },
  {
    id: 1,
    path: '/team',
    element: <Team />,

    title: 'Team',
    status: true,
  },
  {
    id: 2,
    path: '/about-us',
    element: <AboutUs />,
    title: 'About Us',
    status: true,
  },
  {
    id: 3,
    path: '/contact',
    element: <ContactUs />,
    title: 'Contact Us',
    status: true,
  },
  {
    id: 4,
    path: '/login',
    element: <LogIn />,
    title: 'Login',
    status: true,
  },
  {
    id: 5,
    path: '/view-blogs',
    element: <Blogs />,
    title: 'Blogs',
    status: true,
  },
  {
    id: 5,
    path: '/view-blogs/:id',
    element: <BlogsDetails />,
    title: 'Blogs',
    status: true,
  },
  {
    id: 6,
    path: '/newsevent',
    element: <NewsEvents />,
    title: 'Newsevent',
    status: true,
  },
  {
    id: 6,
    path: '/news/:id',
    element: <NewsDetails />,
    title: 'Newsevent',
    status: true,
  },
  {
    id: 6,
    path: '/event/:id',
    element: <EventDetails />,
    title: 'Newsevent',
    status: true,
  },
  {
    id: 7,
    path: '/view-gallery',
    element: <Gallery />,
    title: 'Gallery',
    status: true,
  },

]



export const engineerRoutes = [
  {
    id: 1,
    path: '/profile',
    element: <Profile />,
  },
  {
    id: 2,
    path: '/payment-history',
    element: <PaymentHistory />,
  },

  {
    id: 3,
    path: '/engineer-donation',
    element: <EngineerDonationList />,
  },
]
export const adminRoutes = [
  {
    id: 1,
    path: '/blogs',
    element: <Blog />,
  },
  {
    id: 6,
    path: '/blogs/:state',
    element: <AddBlog />,
  },
  {
    id: 2,
    path: '/category',
    element: <Category />,
  },
  {
    id: 3,
    path: '/category/:state',
    element: <AddCategory />,
  },
  {
    id: 4,
    path: '/sub-category',
    element: <SubCategory />,
  },
  {
    id: 5,
    path: '/sub-category/:state',
    element: <AddSubCategory />,
  },
  {
    id: 7,
    path: '/news',
    element: <News />,
  },
  {
    id: 8,
    path: '/news/:status',
    element: <AddNews />,
  },
  {
    id: 9,
    path: '/gallery',
    element: <AdminGallery />,
  },
  {
    id: 10,
    path: '/gallery/:state',
    element: <AddGallery />,
  },
  {
    id: 11,
    path: '/gallery-image',
    element: <GalleryImage />,
  },
  {
    id: 12,
    path: '/gallery-image/:state',
    element: <AddGalleryImage />,
  },
  {
    id: 13,
    path: '/slider/',
    element: <Slider />,
  },
  {
    id: 14,
    path: '/slider/:state',
    element: <AddSlider />,
  },
  {
    id: 15,
    path: '/banner/',
    element: <Banner />,
  },
  {
    id: 16,
    path: '/banner/:state',
    element: <AddBanner />,
  },
  {
    id: 17,
    path: '/dashboard',
    element: <Dashbaord />,
  },
  {
    id: 18,
    path: '/engineers',
    element: <Engineers />,
  },
  {
    id: 22,
    path: '/create-certificate-plan',
    element: <CreateCertificatePlan />,
  },
  {
    id: 24,
    path: '/create-certificate-plan/:status',
    element: <AddCertificate />,
  },
  {
    id: 19,
    path: '/create-payment-link',
    element: <CreatePaymentLink />,
  },
  {
    id: 20,
    path: '/create-payment-link/add',
    element: <AddPaymentLink />,
  },
  {
    id: 21,
    path: '/contact-us',
    element: <AdminContactUs />,
  },
  {
    id: 25,
    path: '/our-members',
    element: <OurMembers />,
  },
  {
    id: 5,
    path: '/blogs',
    element: <AdminBlog />,
    title: 'Blogs',
    status: true,
  },
  {
    id: 6,
    path: '/news',
    element: <NewsEvents />,
    title: 'Newsevent',
    status: true,
  },
  {
    id: 7,
    path: '/gallery',
    element: <Gallery />,
    title: 'Gallery',
    status: true,
  },
  {
    id: 8,
    path: '/event',
    element: <Event />,
    title: 'Event',
    status: true,
  },
  {
    id: 8,
    path: '/event/:state',
    element: <AddEvent />,
    title: 'Event',
    status: true,
  },
  {
    id: 25,
    path: '/donation',
    element: <DonationList />,
    title: 'Donation',
    status: true,
  },
]

export default routes
