import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getEventByIdFn, patchEventFn, postEventFn } from "Services/Admin/Event";

const AddEvent = () => {
    const { state } = useLocation();
    const { state: status } = useParams();
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    const { mutate: postEvent, isLoading: isPosting } = useMutation(postEventFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("New Event Created.");
                navigate("/event");
            }
        },
        onError: (error) => {
            toast.error("Failed to create event.");
        }
    });

    const { mutate: patchEvent, isLoading: isUpdating } = useMutation(patchEventFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("Event updated successfully");
                navigate("/event");
            }
        },
        onError: (error) => {
            toast.error("Failed to update event.");
        }
    });

    const { data: getEventById, isLoading: isFetching } = useQuery({
        queryFn: () => getEventByIdFn(state),
        queryKey: ["get-event-by-id", state],
        enabled: !!state,
    });

    const event = getEventById?.data?.data;
    const formik = useFormik({
        initialValues: {
            title: event?.title || "",
            content: event?.content || "",
            location: event?.location || "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("title", values.title);
            reqbody.append("content", values.content);
            if (image) reqbody.append("image", image);
            reqbody.append("location", values.location);

            if (status === "update") {
                reqbody.append("event_id", state);
                patchEvent(reqbody);
            } else {
                if (!image) {
                    return toast.error("Image is required.");
                }
                postEvent(reqbody);
            }
        }
    });

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    return (
        <div className="flex flex-col w-full ">
            <div className="px-5 mt-5 bg-white shadow-lg">
                <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
                    {status === "update" ? "Update Event" : "Add Event"}
                </p>
                {isFetching ? (
                    <p className="text-center">Loading...</p>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full pl-3 ">
                                <div>
                                    <p className="p-1 text-sm font-semibold">Title</p>
                                    <input
                                        placeholder=""
                                        className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                        name="title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="w-full pl-3 ">
                                <div>
                                    <p className="p-1 text-sm font-semibold">Location</p>
                                    <input
                                        placeholder=""
                                        className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                        name="location"
                                        value={formik.values.location}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="w-full pl-3 ">
                                <div>
                                    <p className="p-1 text-sm font-semibold">File:</p>
                                    <input
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Content:</p>
                                <ReactQuill
                                    className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                                    value={formik.values.content}
                                    onChange={(value) => formik.setFieldValue('content', value)}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
                            <Button
                                className="!w-32"
                                type="submit"
                                variant="contained"
                                disabled={isPosting || isUpdating}
                            >
                                {status === "update" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default AddEvent;
