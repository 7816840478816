import useAuth from 'hooks/useAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthRequired = () => {
    const location = useLocation();
    const { isTokenExist } = useAuth();

    if (isTokenExist) return <Outlet />
    return <Navigate to={"/login"} replace state={{ from: location }} />
}

export default AuthRequired