import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getBlogByIdFn, patchBlogFn, postBlogFn } from "Services/Admin/Blog";
import { getCategoriesFn } from "Services/Admin/Category";
import { getSubCategoriesFn } from "Services/Admin/SubCategory";

const AddBlog = () => {
    const { state } = useLocation();
    const { state: status } = useParams();
    const [subCategories, setSubCategories] = useState([]);
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    const { data: categoryData } = useQuery({
        queryKey: ["get-categorydata"],
        queryFn: getCategoriesFn,
    });

    const { data: subcategoryData } = useQuery({
        queryKey: ["get-sub-categorydata"],
        queryFn: getSubCategoriesFn,
    });

    const { mutate: postBlog } = useMutation(postBlogFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("New Blog Created.");
                navigate("/blogs");
            }
        }
    });

    const { mutate: patchBlog } = useMutation(patchBlogFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("Blog updated successfully");
                navigate("/blogs");
            }
        }
    })

    const { data: blogByidResponse } = useQuery({
        queryFn: () => getBlogByIdFn(state),
        queryKey: ["get-blog-by-id", state],
        enabled: !!state,
    })

    const blog = blogByidResponse?.data?.data;
    const formik = useFormik({
        initialValues: {
            title: blog?.title || "",
            subTitle: blog?.sub_title || "",
            content: blog?.Content || "",
            category: blog?.category || "",
            subCategory: blog?.sub_category || "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {

            const reqbody = new FormData();
            reqbody.append("title", values.title);
            reqbody.append("sub_title", values.subTitle);
            reqbody.append("Content", values.content);
            reqbody.append("images", image);
            reqbody.append("category", values.category);

            if (values.subCategory) reqbody.append("sub_category", values.subCategory);
            if (status === "update") {
                if (!image) reqbody.delete("images");
                reqbody.append("blog_id", state);
                patchBlog(reqbody);
            } else {
                if (!image) {
                    return toast.error("Image is required.");
                }
                postBlog(reqbody);
            }
        }
    });

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
    };

    // useEffect(() => {
    //     if (subcategoryData && formik.values.category) {
    //         const filteredSubCategories = subcategoryData.data.data.filter(
    //             (item) => item.category === formik.values.category
    //         );
    //         setSubCategories(filteredSubCategories);
    //     } else {
    //         setSubCategories([]);
    //     }
    // }, [formik.values.category, subcategoryData]);

    useEffect(() => {
        if (subcategoryData) setSubCategories(subcategoryData?.data?.data)
    }, [subcategoryData])

    return (
        <div className="flex flex-col w-full ">
            <div className="px-5 mt-5 bg-white shadow-lg">
                <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
                    Add Blog
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-2 gap-5">
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Title</p>
                                <input
                                    placeholder=""
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">SubTitle</p>
                                <input
                                    placeholder=""
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="subTitle"
                                    value={formik.values.subTitle}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Category</p>
                                <select
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="category"
                                    value={formik.values.category}
                                    onChange={formik.handleChange}
                                >
                                    <option>Select category</option>
                                    {categoryData?.data?.data?.map((cat) => (
                                        <option key={cat.id} value={cat.id}>
                                            {cat.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Sub Category</p>
                                <select
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="subCategory"
                                    value={formik.values.subCategory}
                                    onChange={formik.handleChange}
                                    disabled={!formik.values.category}
                                >
                                    <option>Select sub category</option>
                                    {subCategories.map((subCat) => (
                                        <option key={subCat.id} value={subCat.id}>
                                            {subCat.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">File:</p>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full pl-3 ">
                        <div>
                            <p className="p-1 text-sm font-semibold">Content:</p>
                            <ReactQuill
                                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                                value={formik.values.content}
                                onChange={(value) => formik.setFieldValue('content', value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
                        <Button className="!w-32" type="submit" variant="contained">
                            {status === "update" ? "Udpate" : "Add"}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddBlog;
