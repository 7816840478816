import { Button } from '@mui/material';
import Plans from 'Components/Common/Plans';
import React from 'react'
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import { getCertificatePlanFn } from 'Services/Admin/Profile';

const CreateCertificatePlan = () => {
    const { data: getCertificatePlanResponse, refetch } = useQuery({
        queryFn: getCertificatePlanFn,
        queryKey: ['get-certificate-plan']
    })
    const getCertificatePlan = getCertificatePlanResponse?.data?.data || [];

    return (
        <div className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Certificate Plan</p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to="/create-certificate-plan/add">
                    <div className="text-xl font-bold !text-gray-600 p-3">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <Plans refetch={refetch} plans={getCertificatePlan} />
        </div>
    )
}

export default CreateCertificatePlan;