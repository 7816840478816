const { privateAxiosIntance, publicAxiosInstance } = require("Configs/axios")
const { protectedRoutes, API_URLS } = require("Configs/urls")

export const getCertificatePlanFn = () => {
    try {
        const response = publicAxiosInstance.get(API_URLS.certificate_plan);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCertificateById = (id) => {
    try {
        const response = publicAxiosInstance.get(`${API_URLS.certificate_plan}?certificate_plan_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const postCertificatePlanFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.certificate_plan, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchCertificatePlanFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.patch(API_URLS.certificate_plan, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCertificatePlanFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.delete(API_URLS.certificate_plan, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const activateCertificatePlanFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.post(protectedRoutes.status_plan_certificate, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCertificateFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.get_ceritificate, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCertificatePaymentSuccessFn = (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.get_ceritificate_payment_success, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getProfileFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.profile);
        return response;
    } catch (error) {
        throw error;
    }
}

export const postProfileFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.profile, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCountryFn = () => {
    try {
        const response = privateAxiosIntance.get(API_URLS.country_list_api);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getStateFn = (query) => {
    try {
        const response = privateAxiosIntance.get(`${API_URLS.state_list_api}?country_id=${query}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCityFn = (query) => {
    try {
        const response = privateAxiosIntance.get(`${API_URLS.city_list_api}?state_id=${query}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const buyCertificatePlanFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.buy_certificate, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getOurMembersFn = ({ search, page }) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.our_members}?search=${search}&page=${page}`);
        return response;
    } catch (error) {
        throw error;
    }
}