import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getBlogFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.blog);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getBlogByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.blog}?blog_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBlogByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.blog}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postBlogFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.blog, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchBlogFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.blog, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}