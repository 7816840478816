import { CopyAllOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useCopied from 'hooks/useCopied';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getCreatePaymentLinkFn } from 'Services/Admin/CreatePaymentLink';
import { getOurMembersFn } from 'Services/Admin/Profile';

const OurMembers = () => {
    const { copy } = useCopied();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);

    const { data: getOurMembers } = useQuery({
        queryKey: ["get-our-members", page, search],
        queryFn: () => getOurMembersFn({ search, page }),
        keepPreviousData: true,
        staleTime: 5000
    });

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (getOurMembers?.data) {
            setTotalPage(getOurMembers.data.total_pages || 1);
        }
    }, [getOurMembers]);

    console.log(getOurMembers);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Our Members</p>
            <div className="flex items-center justify-between my-2 mt-4">
                <div className="flex items-center my-2 mt-4">
                    <span className="flex gap-3">
                        <input
                            className="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                            placeholder="Search for anything..."
                            type="text"
                            name="search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </span>
                    {/* {getOurMembers?.data?.total_sum && (
                        <span className='text-blue-800'>
                            <b>Total Received:</b> ₹{getOurMembers.data.total_sum}
                        </span>
                    )} */}
                </div>
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            {['Member ID', 'Engineer Name', 'Engineer Mail', 'Amount (₹)', "Date", 'Payment No', 'Status'].map((header, index) => (
                                <TableCell
                                    key={index}
                                    style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                    className="!text-center !text-gray-800 !font-bold"
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getOurMembers?.data?.data?.map((row) => {
                            // const expiryTime = new Date(row?.expiry_time).toLocaleTimeString();
                            const createdDate = new Date(row?.is_created).toLocaleDateString();

                            return (
                                <TableRow key={row?.id} className="!text-slate-700">
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.engineer?.member_id || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.engineer?.name || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.engineer?.email || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.total_payable_amount || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {createdDate || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_no || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.status || "--"}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>
        </section>
    );
};

export default OurMembers;
