import { CopyAllOutlined } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useCopied from 'hooks/useCopied';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getCreatePaymentLinkFn } from 'Services/Admin/CreatePaymentLink';

const CreatePaymentLink = () => {
    const { copy } = useCopied();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [totalPage, setTotalPage] = useState(1);

    const { data: getCreatePaymentLink } = useQuery({
        queryKey: ["get-create-payment-link", page, search],
        queryFn: () => getCreatePaymentLinkFn({ search, page }),
        keepPreviousData: true,
        staleTime: 5000
    });

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (getCreatePaymentLink?.data) {
            setTotalPage(getCreatePaymentLink.data.total_pages || 1);
        }
    }, [getCreatePaymentLink]);

    return (
        <section className='p-4'>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Payment</p>
            <div className="flex items-center justify-between my-2 mt-4">
                <div className="flex items-center my-2 mt-4">
                    <span className="flex gap-3">
                        <input
                            className="placeholder:italic placeholder:text-slate-400 block mx-3 bg-white w-80 border border-slate-300 rounded-md py-2 px-2 pr-3 shadow-sm focus:outline-none focus:border-slate-600 focus:ring-slate-500 focus:ring-1 sm:text-sm"
                            placeholder="Search for anything..."
                            type="text"
                            name="search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </span>
                    {getCreatePaymentLink?.data?.total_payment_amount && (
                        <span className='text-blue-800'>
                            <b>Total Received:</b> ₹{getCreatePaymentLink.data.total_payment_amount}
                        </span>
                    )}
                </div>
                <Button variant='contained' onClick={() => navigate("/create-payment-link/add")}>
                    Create Payment Link
                </Button>
            </div>
            <TableContainer
                component={Paper}
                sx={{ width: "100%", maxHeight: 440, marginTop: "1rem" }}
                className="!text-black !mx-auto !bg-white overflow-auto px-2"
            >
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            {['ID', 'Engineer Name', 'Engineer Mail', 'Payment Id', 'Payment Amount (₹)', 'Payment For', 'Expiry Time', 'Payment Link', 'Created Date', 'Status'].map((header, index) => (
                                <TableCell
                                    key={index}
                                    style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}
                                    className="!text-center !text-gray-800 !font-bold"
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getCreatePaymentLink?.data?.data?.map((row) => {
                            const expiryTime = new Date(row?.expiry_time).toLocaleTimeString();
                            const createdDate = new Date(row?.created_date).toLocaleDateString();

                            return (
                                <TableRow key={row?.id} className="!text-slate-700">
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.id || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.Engineer && row?.Engineer[0]?.name || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.Engineer && row?.Engineer[0]?.email || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_id || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_amount || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {row?.payment_for || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {expiryTime || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        <span onClick={() => copy(row?.payment_link)} className='text-blue-700 cursor-pointer'>
                                            Copy Link <CopyAllOutlined fontSize='small' />
                                        </span>
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif" }}>
                                        {createdDate || "--"}
                                    </TableCell>
                                    <TableCell className="!text-center !text-[12px]" style={{ borderRight: "1px solid #D0D0D0", fontFamily: "'Poppins', sans-serif", padding: '1rem' }}>
                                        {
                                            row?.status === "Paid" && <span className='text-green-800 text-sm font-bold'><CheckCircleIcon /></span>
                                        }
                                        {
                                            row?.status === "Unpaid" && <span className='text-blue-800 font-bold px-4 py-2 rounded-sm'>
                                                Unpaid
                                            </span>
                                        }
                                        {
                                            row?.status === "Expired" && <span className='bg-red-600 text-white px-4 py-2 rounded-sm'>Expired</span>
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="flex justify-center p-2">
                <Pagination
                    page={page}
                    size="small"
                    count={totalPage}
                    variant="outlined"
                    color="primary"
                    onChange={handleChange}
                />
            </div>
        </section>
    );
};

export default CreatePaymentLink;
