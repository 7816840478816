import { Button } from '@mui/material';
import { imageFormat } from 'Configs/axios';
import React from 'react'
import { useMutation, useQuery } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteCategoryByIdFn, getCategoriesFn } from 'Services/Admin/Category';

const Category = () => {
    const navigate = useNavigate();
    const { data: getCategoriesResponse, refetch } = useQuery({
        queryKey: "get-cateogries",
        queryFn: getCategoriesFn
    })

    const { mutate: deleteCategoryById } = useMutation(deleteCategoryByIdFn, {
        onSuccess: (response) => {
            refetch();
            toast.success("Category deleted successfully.")
        }
    })

    const handleDelete = (id) => {
        const formData = new FormData();
        formData.append("category_id", id);
        deleteCategoryById(formData);
    }

    return (
        <>
            <p className="my-3 text-5xl text-center text-blue-900 underline">
                Category
            </p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to="/category/add">
                    <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid justify-between md:grid-cols-2 lg:grid-cols-4">
                {getCategoriesResponse?.data?.data?.map((item) => {
                    return (
                        <div key={item.id} className="flex flex-col justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
                            <div className="flex justify-between">
                                <div className="flex flex-wrap my-3 ml-2 w-[40%]">
                                    <p className="text-lg text-black">{item.name}</p>
                                </div>
                                <div className="flex flex-wrap ml-2  justify-evenly my-2 w-[60%]">
                                    <p className="text-xl text-black text-right  underline">
                                        <img src={imageFormat(item.images)} alt="" className="!w-[100%] !h-20" />
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full px-5">
                                <div className="px-5 mt-8 ">
                                    <Button
                                        onClick={() =>
                                            navigate(`/category/update`, { state: item.id })
                                        }
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-8 ">
                                    <Button
                                        onClick={() => handleDelete(item.id)}
                                        variant="outlined"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default Category;    