import { Avatar, Button } from '@mui/material';
import { imageFormat } from 'Configs/axios';
import useAuth from 'hooks/useAuth';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getProfileFn } from 'Services/Admin/Profile';

const AdminHeader = () => {
    const navigate = useNavigate();
    const { setIsTokenExist, setUserType, userType } = useAuth();
    const { data: getProfileResponse } = useQuery({
        queryFn: getProfileFn,
        queryKey: ["get-profile", userType === "Engineer"],
        enabled: userType === "Engineer"
    });

    const handleLogout = () => {
        localStorage.removeItem("sewaup");
        localStorage.removeItem("user_type");
        setIsTokenExist(null);
        setUserType(null);
        navigate("/");
    }

    return (
        <>
            <div className="flex items-center justify-between h-16 p-2 bg-white rounded-lg shadow">
                <p className="text-xl font-bold">{userType}</p>
                <div className="flex items-center justify-center gap-3">
                    <Avatar alt="D" src={imageFormat(getProfileResponse?.data?.data?.image)} className=" !bg-gray-400" />
                    {userType === "Engineer" && <p className="font-semibold capitalize">{getProfileResponse?.data?.data?.name}</p>}
                    <Button
                        onClick={handleLogout}
                        disableElevation
                        variant="contained"
                        className="!bg-red-500 !capitalize text-white"
                    >
                        Log Out
                    </Button>
                </div>
            </div>
        </>
    );
}

export default AdminHeader;