import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getCategoriesFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.category_api);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getCategoryByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.category_api}?category_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCategoryByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.category_api}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postCategoriesFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.category_api, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchCategoriesFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.category_api, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}