import * as Yup from 'yup';

// Define validation schema
export const ProfileSchema = Yup.object({
    id: Yup.string().required('ID is required'),
    prefix: Yup.string().required('Prefix is required'),
    name: Yup.string().required('Name is required'),
    about: Yup.string().required('About is required'),
    dob: Yup.date().required('Date of birth is required').max(new Date(), 'Date of birth cannot be in the future'),
    gender: Yup.string().required('Gender is required'),
    fathername: Yup.string().required('Father Name is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    district: Yup.string().required('District is required'),
    block: Yup.string().required('Block is required'),
    mobile: Yup.string().matches(/^\d+$/, 'Contact number must be a number').required('Contact number is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    designation: Yup.string()
});
