import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
} from "@mui/material";
import classNames from "classnames";
import useAuth from "hooks/useAuth";
import SidebarNav, { adminSidebarNav } from "Mock/SidbarNav";
import { useNavigate } from "react-router-dom";
import logo from 'assets/logo.jpeg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Sidebar = () => {
    const navigate = useNavigate();
    const { userType } = useAuth()

    return (
        <List className="!relative !p-2 min-w-fit">
            <ListItem className="!py-0 !mb-8 flex flex-col">
                <img src={logo} alt="logo" className="w-20 mx-auto" />
            </ListItem>
            <Divider />
            <ListItem className="!py-0 px-0 my-2">
                <Button variant="contained" color="error" fullWidth startIcon={<ArrowBackIcon />} onClick={() => navigate("/")}> Back</Button>
            </ListItem>
            {userType === "Engineer" && SidebarNav?.map((nav, idx) => {
                return (
                    <ListItemButton
                        key={idx}
                        onClick={() => navigate(nav.navLink)}
                        className={classNames(
                            "!rounded !p-2",
                            window.location.pathname === nav.navLink && "!bg-gray-200"
                        )}
                    >
                        <ListItemIcon
                            className={classNames(
                                window.location.pathname === nav.navLink && "!text-white"
                            )}
                        >
                            {nav.navIcon}
                        </ListItemIcon>
                        {nav.navItem}
                    </ListItemButton>
                );
            })}
            {userType === "Admin" && adminSidebarNav?.map((nav, idx) => {
                return (
                    <ListItemButton
                        key={idx}
                        onClick={() => navigate(nav.navLink)}
                        className={classNames(
                            "!rounded !p-2",
                            window.location.pathname === nav.navLink && "!bg-gray-200"
                        )}
                    >
                        <ListItemIcon
                            className={classNames(
                                window.location.pathname === nav.navLink && "!text-white"
                            )}
                        >
                            {nav.navIcon}
                        </ListItemIcon>
                        {nav.navItem}
                    </ListItemButton>
                );
            })}
        </List>
    );
};

export default Sidebar;
