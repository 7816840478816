import { Button, Autocomplete, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postCategoriesFn } from "Services/Admin/Category";
import { postCreatePaymentLinkFn } from "Services/Admin/CreatePaymentLink";
import { getEngineersListFn } from "Services/Admin/Engineers";
import * as Yup from "yup";

export default function AddPaymentLink() {
    const navigate = useNavigate();

    const { data: getEngineers } = useQuery({
        queryKey: ["get-engineers"],
        queryFn: getEngineersListFn
    });

    const engineersOptions = getEngineers?.data?.data?.map(engineer => ({
        label: engineer.email,
        id: engineer.id
    }));

    const { mutate: postCreatePaymentLink } = useMutation(postCreatePaymentLinkFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("Payment Link Created Successfully");
                navigate("/create-payment-link");
            }
        }
    });

    const validationSchema = Yup.object({
        engineer_id: Yup.string().required("Engineer is required"),
        payment_amount: Yup.number().required("Payment amount is required").positive("Amount must be positive"),
        payment_for: Yup.string().required("Payment purpose is required"),
        expiry_time: Yup.number()
            .required("Expiry time is required")
            .min(15, "Expiry time must be at least 15 minutes")
            .max(60, "Expiry time must be no more than 60 minutes"),
    });

    const formik = useFormik({
        initialValues: {
            engineer_id: "",
            payment_amount: "",
            payment_for: "",
            expiry_time: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("engineer_id", values.engineer_id);
            formData.append("payment_amount", values.payment_amount);
            formData.append("payment_for", values.payment_for);
            formData.append("expiry_time", values.expiry_time);
            console.log(values)
            postCreatePaymentLink(formData);
        },
    });

    return (
        <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
            <div className="flex flex-col justify-center w-full lg:w-3/5">
                <p className="my-3 text-2xl text-center text-blue-900 underline">Create Payment Link</p>
                <div className="mt-5 bg-white shadow-lg">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-2 border-gray-300 rounded-md">
                            <div className="flex flex-col gap-5">
                                <div className="w-full pl-3">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Engineer</p>
                                        <Autocomplete
                                            id="engineer_id"
                                            options={engineersOptions || []}
                                            getOptionLabel={(option) => option.label || ""}
                                            onChange={(event, value) =>
                                                formik.setFieldValue("engineer_id", value?.id || "")
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select Engineer"
                                                    variant="outlined"
                                                    error={formik.touched.engineer_id && Boolean(formik.errors.engineer_id)}
                                                    helperText={formik.touched.engineer_id && formik.errors.engineer_id}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Payment Amount</p>
                                        <TextField
                                            id="payment_amount"
                                            placeholder="Enter Payment Amount"
                                            value={formik.values.payment_amount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.payment_amount && Boolean(formik.errors.payment_amount)}
                                            helperText={formik.touched.payment_amount && formik.errors.payment_amount}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Payment For</p>
                                        <TextField
                                            id="payment_for"
                                            placeholder="Enter Payment Purpose"
                                            value={formik.values.payment_for}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.payment_for && Boolean(formik.errors.payment_for)}
                                            helperText={formik.touched.payment_for && formik.errors.payment_for}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Expiry Time</p>
                                        <TextField
                                            id="expiry_time"
                                            placeholder="Enter Expiry Time (minutes)"
                                            value={formik.values.expiry_time}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.expiry_time && Boolean(formik.errors.expiry_time)}
                                            helperText={formik.touched.expiry_time && formik.errors.expiry_time}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            variant="outlined"
                                            type="number"
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <Button type="submit" variant="contained">
                                        Create Payment Link
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
