import { Divider } from '@mui/material';
import { orange } from '@mui/material/colors';
import { imageFormat } from 'Configs/axios';
import { postEngineerApprovalStatusFn } from 'Services/Admin/Engineers';
import logo from 'assets/logo-transparent.png';
import panchayati from 'assets/panchayati-raj.png';
import domtoimage from 'dom-to-image-more';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';


const Certificate = ({ data, setApproved, refetch }) => {

    const { mutate } = useMutation(postEngineerApprovalStatusFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("Approved");
                setApproved(null);
                refetch();
            }
        },
        onError: (error) => {
            toast.error("Failed to approve the engineer.");
            setApproved(null);
            console.error("Mutation Error:", error);
        }
    });

    useEffect(() => {
        if (data) {
            const captureAndSubmit = async () => {
                try {
                    if (data?.image && data?.member_id && data?.fathername && data?.name && data?.district && data?.mobile && data?.block) {
                        const blob = await domtoimage.toBlob(document.getElementById('certificate-generate'));
                        const formData = new FormData();
                        formData.append("certificate", blob);
                        formData.append("status", "Approved");
                        formData.append("engineer_id", data.id);

                        mutate(formData);
                    } else {
                        setApproved(null);
                        toast.error("Profile is incomplete.")
                    }
                } catch (error) {
                    setApproved(null);
                    console.error('Error capturing or submitting certificate image:', error);
                }
            };

            captureAndSubmit();
        }
    }, [data, mutate]);

    return (
        <main className='relative -z-20'>
            <div id="certificate-generate" className='w-[500px] h-[300px] border bg-gray-200 rounded-xl text-black'>
                <div className='p-2 mt-1 flex justify-around items-center'>
                    <p className='w-16 h-16 overflow-hidden'>
                        <img src={logo} alt="CEWAUP" className='w-full h-full object-cover' />
                    </p>
                    <div className='font-bold text-center'>
                        <p className='text-red-600'>Consulting Enginner & Architect</p>
                        <p>Welfare Association</p>
                    </div>
                    <p className='h-16 overflow-hidden'>
                        <img src={panchayati} alt="CEWAUP" className='w-full h-full object-cover py-2 px-4' />
                    </p>
                </div>
                <Divider color={orange[800]} />
                <div className='pl-6 pt-2 flex gap-6'>
                    <p className='w-32 mt-2 h-40 overflow-hidden rounded-md border-2 shadow-2xl border-orange-600'>
                        <img src={imageFormat(data?.image)} alt="" className='w-full h-full object-cover' />
                    </p>
                    <div className='w-full flex-1'>
                        <p className='font-bold underline text-center mb-2 text-orange-600'>IDENTITY</p>
                        <div className='text-sm'>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>ID</span><span>:</span></p>
                                <p>{data?.member_id}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>Name</span><span>:</span></p>
                                <p>{data?.name}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>Father Name</span><span>:</span></p>
                                <p>{data?.fathername}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>District</span><span>:</span></p>
                                <p>{data?.district}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>Division</span><span>:</span></p>
                                <p>{data?.block}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>Mobile</span><span>:</span></p>
                                <p>{data?.mobile}</p>
                            </div>
                            <div className='grid grid-cols-[130px_1fr] gap-2'>
                                <p className='flex justify-between items-center font-medium'><span>Validity</span><span>:</span></p>
                                <p>{new Date(data?.certificate_validity).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Certificate;
