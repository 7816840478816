const { publicAxiosInstance } = require("Configs/axios");
const { API_URLS } = require("Configs/urls");

export const sentOtpFn = async (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.sendOTP, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const verifyOTPFn = async (reqBody) => {
    try {
        const response = publicAxiosInstance.post(API_URLS.verifyOTP, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}