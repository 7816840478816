/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import axios from 'axios';
import { Call, Mail, SwitchAccount } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { publicAxiosInstance } from 'Configs/axios';

const validationSchema = Yup.object({
  name: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string().matches(/^\d+$/, 'Must be a valid mobile number').required('Mobile Number is required'),
  description: Yup.string().required('Description is required'),
});

const ContactUs = () => {
  const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
    try {
      console.log('Submitting values:', values);
      const response = await publicAxiosInstance.post(
        '/blog/contact-us-api/',
        values,
      );
      console.log('Form submitted successfully:', response.data);
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error('Error submitting form data:', error);
      if (error.response && error.response.data) {
        setErrors({ general: error.response.data.message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className='pt-10 bg-white md:pt-0 '>
      <div className="relative flex flex-col items-center justify-center bg-white">
        <img
          src="https://www.sresindia.org/images/newsbg.jpg"
          alt="Contact Us Background"
          className="w-full lg:h-[300px] h-[600px] object-cover"
        />
        <p className="absolute text-2xl font-bold text-black lg:top-10 top-8 md:top-5 md:text-4xl">
          CONTACT INFORMATION
        </p>
        <div className="absolute bottom-5 w-[90%] lg:w-[60%] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-5 p-4">
          <div className="flex flex-col items-start p-4 bg-white border-2 border-gray-400">
            <p className="text-xl font-bold text-black md:text-2xl">Address</p>
            <Divider className="bg-gray-300" />
            <div className='flex gap-1'>
              <SwitchAccount className="text-[#d94148]" fontSize="medium" />
              <p className="text-sm font-bold text-gray-600 md:text-base">
                C block Indira nagar Lucknow
              </p>
            </div>
          </div>
          <div className="flex flex-col items-start p-4 bg-white border-2 border-gray-400">
            <p className="text-xl font-bold text-black md:text-2xl">Phone</p>
            <Divider className="bg-gray-300" />
            <div className="flex gap-1 whitespace-normal">
              <Call className="text-[#d94148]" fontSize="medium" />
              <div className='flex'>
                <div className="text-base  md:text-base text-[#d94148] font-semibold">
                  <a href="tel:+919039131372" className='font-bold text-gray-600 md:text-base'>+91-9039131372</a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start p-4 bg-white border-2 border-gray-400 lg:p-5">
            <p className="font-bold text-black md:text-2xl">Email</p>
            <Divider className="bg-gray-300" />
            <div className='flex '>
              <Mail className="text-[#d94148]" fontSize="medium" />
              <p className="text-base font-bold text-gray-600 md:text-base">ceawaup@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-center gap-10 px-5 pt-20 lg:flex-row lg:px-0'>
        <div className='flex flex-col w-full lg:w-[25%]'>
          <p className='mb-5 text-4xl font-bold text-black'>GET IN TOUCH</p>
          <Formik
            initialValues={{
              name: '',
              email: '',
              mobile: '',
              description: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col w-full">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-2">
                    <label className="text-black">Name</label>
                    <Field
                      className="w-full p-3 text-black border-2 border-black"
                      type="text"
                      name="name"
                    />
                    <ErrorMessage name="name" component="div" className="text-red-600" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-black">Email</label>
                    <Field
                      className="w-full p-3 text-black border-2 border-black"
                      type="email"
                      name="email"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-600" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-black">Number</label>
                    <Field
                      className="w-full p-3 text-black border-2 border-black"
                      type="text"
                      name="mobile"
                    />
                    <ErrorMessage name="mobile" component="div" className="text-red-600" />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="text-black">Description</label>
                    <Field
                      as="textarea"
                      className="w-full p-3 text-black border-2 border-black"
                      name="description"
                    />
                    <ErrorMessage name="description" component="div" className="text-red-600" />
                  </div>
                </div>
                <div className='flex items-center justify-center p-2'>
                  <button
                    type="submit"
                    className="bg-[#d94148] text-white mt-5 p-4 w-32 text-xl font-bold"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='flex flex-col w-full lg:w-[30%]'>
          <p className='mb-5 text-4xl font-bold text-black'>OFFICE LOCATION</p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.111275267226!2d-122.39997248468165!3d37.79406567975736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808f41c0c29f%3A0x12a14e6763ec6387!2sSalesforce%20Tower!5e0!3m2!1sen!2sus!4v1628817654677!5m2!1sen!2sus"
            className='w-full h-[400px]'
            allowFullScreen
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
