import { Login } from '@mui/icons-material';
import AuthRequired from 'Components/Auth/AuthRequired';
import Donation from 'Components/Common/Donation';
import Layout from 'Layout';
import AdminLayout from 'Layout/AdminLayout';
import routes, { adminRoutes, engineerRoutes } from 'Routes';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Router component for defining routes using React Router.
 * @returns {JSX.Element} - JSX element representing the router.
 */
const Router = () => {
  const queryClient = new QueryClient();
  const { userType } = useAuth();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  // useEffect(() => {
  //   localStorage.setItem("sewaup", "loadsfasdf");
  //   localStorage.setItem("user_type", "Engineer");
  // }, [])


  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Donation />
      <Routes>
        <Route element={<AdminLayout />}>
          <Route element={<AuthRequired />}>
            <Route path='*' element={<Login />} />
            {userType === "Admin" &&
              adminRoutes.map((route) => (
                <Route key={route.id} path={route.path} element={route.element} />
              ))
            }
            {userType === "Engineer" &&
              engineerRoutes.map((route) => (
                <Route key={route.id} path={route.path} element={route.element} />
              ))
            }
          </Route>
        </Route>
        <Route element={<Layout />}>
          {/* Map through routes and render Route components for each active route */}
          {routes.map(
            (route) =>
              route.status && <Route key={route.id} path={route.path} element={route.element} />
          )}
        </Route>
      </Routes>
    </QueryClientProvider>
  );
};

export default Router;
