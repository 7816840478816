import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getGalleryFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.gallery);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getGalleryByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.gallery}?gallery_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteGalleryByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.gallery}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postGalleryFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.gallery, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchGalleryFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.gallery, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}