import { publicAxiosInstance } from 'Configs/axios';
import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const News = () => {
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const Navigate = useNavigate();

  const newsFn = async () => {
    try {
      const response = await publicAxiosInstance.get('/blog/news-api/');
      setData(response?.data?.data);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  useEffect(() => {
    newsFn();
  }, []);

  const eventFn = async () => {
    try {
      const response = await publicAxiosInstance.get('/blog/event-api/');
      setEventData(response?.data?.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    eventFn();
  }, []);

  return (
    <div className="relative py-16">
      <img
        src="https://www.sresindia.org/images/eventsbg.jpg"
        alt=""
        className="absolute top-0 left-0 z-0 object-cover w-full h-full"
      />
      <div className="relative z-10 flex justify-center">
        <div className="flex flex-col items-start justify-center w-full gap-10 p-4 bg-opacity-50 rounded lg:flex-row lg:items-center lg:w-3/4">
          <div className="flex flex-col w-full bg-white lg:w-1/2">
            <p className="text-white bg-[#d94148] text-2xl font-bold p-4 text-center">Latest News</p>
            {data.map((news) => (
              <div key={news.id} className="flex flex-col gap-1 p-2 border-b border-red-200" >
                <p className="text-xl font-medium text-black">{news.title}</p>
                <p>{news.created_at}</p>
              </div>
            ))}
            <div className="flex justify-center items-center bg-[#d94148] text-white p-4 text-xl font-bold cursor-pointer">
              <Link to="/newsevent">VIEW ALL</Link>
            </div>
          </div>


          <div className="flex flex-col w-full bg-white lg:w-1/2">
            <p className="text-white bg-[#d94148] text-xl font-bold p-4 text-center">MEETING & EVENTS</p>
            <Link to="/newsevent">
              <div className="flex flex-col gap-3 p-4 text-xl">
                {eventData.map((event) => (
                  <div key={event.id} className="flex flex-col border-red-200">

                    <p className="text-xl font-medium text-black ">{event.title}</p>
                    <p>{event.created_at}</p>
                  </div>
                ))}
              </div>
            </Link>
            <div className="flex justify-center items-center bg-[#d94148] text-white p-4 text-xl font-bold cursor-pointer">
              <Link to="/newsevent">VIEW ALL</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
