import React from 'react'
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

const PaymentSuccess = ({ orderId }) => {
    return (
        <div className="fixed w-full h-screen bg-black z-50 bg-opacity-20 flex justify-center top-0 left-0">
            <div className="w-[300px] bg-white self-start mt-[10%] p-4 py-12 rounded-xl shadow-lg flex flex-col gap-2 items-center justify-center">
                <p className="w-24 h-24 overflow-hidden grid place-content-center rounded-full bg-green-600">
                    <PriceCheckIcon className="text-white !text-7xl scale-up" />
                </p>
                <h3 className="text-3xl font-bold text-center text-green-600">Success!</h3>
                <p className="flex justify-center flex-col items-center text-center">
                    <span className="font-bold">Order Id </span>
                    {orderId}
                </p>
                <p className="text-center">Payment Successfull.</p>
            </div>
        </div>
    )
}

export default PaymentSuccess;