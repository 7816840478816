import { Link } from 'react-router-dom';

import pic3 from './images/pic3.jpeg';

import pic9 from './images/pic9.jpeg';

const Team = () => {
  const images = [
    {
      id: 2,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic7.jpeg",
      name: 'अनिल कुमार सिंह',
      designation: 'मंत्री',
    },
    {
      id: 19,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic5.jpeg",
      name: 'ज्योत्सना',
      designation: 'सचिव',
    },
    {
      id: 20,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic9.jpeg",
      name: 'अलका वर्मा',
      designation: 'संयुक्त सचिव',
    },
    {
      id: 16,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic16.jpeg",
      name: 'जया प्रकाश',
      designation: 'संगठन सचिव',
    },
    {
      id: 8,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic3.jpeg",
      name: 'अमित चौरसिया',
      designation: 'सह सचिव',
    },
    {
      id: 1,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic1.jpeg",
      name: 'सुनील मौर्या',
      designation: 'संयुक्त मंत्री',
    },
    {
      id: 10,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic6.jpeg",
      name: 'मोहम्मद आरिफ',
      designation: 'संगठन मंत्री',
    },
    {
      id: 17,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/PIC11.jpeg",
      name: 'नावेद निसार',
      designation: 'सहसंगठन मंत्री',
    },

    {
      id: 13,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic13.jpeg",
      name: 'नागेंद्र मौर्या',
      designation: 'सहसंगठन मंत्री',
    },
    {
      id: 7,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic4.jpeg",
      name: 'मिराज अहमद',
      designation: 'सहसंगठन मंत्री',
    },
    {
      id: 6,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic2+.jpeg",
      name: 'अमिताभ मिश्रा',
      designation: 'सहसंगठन मंत्री',
    },
    {
      id: 3,
      image: pic3,
      name: 'रोहित चौधरी',
      designation: 'प्रिंट मीडिया प्रभारी',
    },



    {
      id: 9,
      image: pic9,
      name: 'कृष्ण कुमार रॉय',
      designation: 'उपमंत्री',
    },




    {
      id: 15,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic15.jpeg",
      name: 'अंकित त्रिपाठी ',
      designation: 'सहसंगठन मंत्री',
    },


    {
      id: 18,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/PIC10.jpeg",
      name: 'मोहम्मद फरमान',
      designation: 'सहसंगठन मंत्री',
    },

    {
      id: 18,
      image: "https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic20.jpeg",
      name: 'अमित यादव',
      designation: 'सोशल मीडिया प्रभारी',
    },

  ];

  const members = [
    { name: 'मीनाक्षी तिवारी', position: 'उपाध्यक्ष' },
    { name: 'सुनील दुबे', position: 'अध्यक्ष' },
    { name: 'जीशान सिद्दीकी', position: 'महामंत्री' },
    { name: 'शिवसागर यादव', position: 'कोषाध्यक्ष' },
    { name: 'अनिल कुमार सिंह', position: 'मंत्री' },
    { name: 'ज्योत्सना', position: 'सचिव' },
    { name: 'अलका वर्मा', position: 'संयुक्त सचिव' },
    { name: 'जया प्रकाश', position: 'संगठन सचिव' },
    { name: 'अमित चौरसिया', position: 'सह सचिव' },
    { name: 'सुनील मौर्या', position: 'संयुक्त मंत्री' },
    { name: 'मोहम्मद आरिफ', position: 'संगठन मंत्री' },
    { name: 'नावेद निसार', position: 'सहसंगठन मंत्री' },
    { name: 'नागेंद्र मौर्या', position: 'सहसंगठन मंत्री' },
    { name: 'मिराज अहमद', position: 'सहसंगठन मंत्री' },
    { name: 'अमिताभ मिश्रा', position: 'सहसंगठन मंत्री' },
    { name: 'मोहम्मद फरमान', position: 'सहसंगठन मंत्री' },
    { name: 'रोहित चौधरी', position: 'प्रिंट मीडिया प्रभारी' },
    { name: 'अमित यादव', position: 'सोशल मीडिया प्रभारी' },
    { name: 'विपुल पांडे', position: 'मुख्य सूचना प्रभारी' },
    { name: 'कृष्ण कुमार रॉय', position: 'उपमंत्री' },
    { name: 'पंकज मिश्रा', position: 'सहसंगठन मंत्री' },


  ];

  return (
    <div className="flex flex-col justify-center h-auto p-2 text-black bg-white">
      <div className="container flex flex-col items-center gap-5">
        <p className="pt-10 text-4xl font-bold text-center text-black ">
          Meet Our Beautiful Team
        </p>
        <p className="text-xl lg:w-[40%] w-[100%] text-center">
          Our philosophy is simple; hire great people and give them the
          resources and support to do their best work.
        </p>
        <div className="flex items-center gap-5">
          <Link to="/contact">
            <button className="p-4 text-xl font-bold bg-white shadow-xl">
              Contact us
            </button>
          </Link>
          <Link
            to="/login"
            className="p-4 text-xl font-bold bg-blue-500 shadow-xl"
          >
            Login
          </Link>
        </div>
        <div className='flex gap-6 pt-4'>
          <div className='flex flex-col items-center w-[180px] h-[250px] transition-transform  hover:border-4 p-2 border-gray-200  transform hover:scale-105 '>
            <img src='https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic12.jpeg' alt='' className='!h-40'></img>
            <p className="pt-2 text-sm font-semibold">मीनाक्षी तिवारी</p>
            <p className="text-xs text-gray-500">उपाध्यक्ष</p>
          </div>
          <div className='flex flex-col items-center w-[180px] h-[250px] transition-transform   hover:border-4 p-2 border-gray-200  transform hover:scale-105'>
            <img src='https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic14.jpeg' alt='' className='!h-40'></img>
            <p className="pt-2 text-sm font-semibold">सुनील दुबे</p>
            <p className="text-xs text-gray-500">अध्यक्ष</p>
          </div>
          <div className='flex flex-col items-center w-[180px] h-[250px]  hover:border-4 p-2 border-gray-200  transition-transform transform hover:scale-105 '>
            <img src='https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic8.jpeg' alt='' className='!h-40 w-48'></img>
            <p className="pt-2 text-sm font-semibold">जीशान सिद्दीकी'</p>
            <p className="text-xs text-gray-500">महामंत्री</p>
          </div>
          <div className='flex flex-col items-center w-[180px]  hover:border-4 p-2 border-gray-200  h-[250px] transition-transform transform hover:scale-105 '>
            <img src='https://careerads.s3.ap-south-1.amazonaws.com/ourTeam/pic111.jpeg' alt='' className='!h-40 '></img>
            <p className="pt-2 text-sm font-semibold">शिवसागर यादव</p>
            <p className="text-xs text-gray-500">कोषाध्यक्ष</p>
          </div>

        </div>


        <div className="grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-6 my-8 text-center max-w-[1200px]">
          {images.map((img) => (
            <div
              className="flex flex-col items-center w-[180px] h-[250px] transition-transform transform hover:scale-105"
              key={img.id}
            >
              <img
                src={img.image}
                alt={img.name}
                className="w-[150px] h-[150px] rounded-full border-4 object-cover object-center max-w-xs max-h-xs border-gray-300 shadow-xl"
              />
              <div className="mt-4">
                <p className="text-sm font-semibold">{img.name}</p>
                <p className="text-xs text-gray-500">{img.designation}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-white p-3 md:w-[100%] lg:w-[50%] mb-5 shadow-2xl flex flex-col">
          <div className="overflow-x-auto">
            <table className="w-full text-xl font-bold border border-gray-300">
              <thead>
                <tr className="bg-gray-100 border-b border-gray-300">
                  <th className="p-2 text-left border-r border-gray-300">
                    Name
                  </th>
                  <th className="p-2 text-center border-r border-gray-300">
                    Position
                  </th>
                </tr>
              </thead>
              <tbody>
                {members.map((m, idx) => (
                  <tr key={idx} className="border-b border-gray-300">
                    <td className="p-2 border-r border-gray-300">{m.name}</td>
                    <td className="p-2 text-center border-r border-gray-300">
                      {m.position}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
