import useAuth from 'hooks/useAuth';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sentOtpFn, verifyOTPFn } from 'Services/login';
import logo from "./logo.jpeg";

const LogIn = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputsRef = useRef([]);
  const [email, setEmail] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [loading, setLoading] = useState(false);
  const [canResend, setCanResend] = useState(true);
  const navigate = useNavigate();
  const { setIsTokenExist, setUserType } = useAuth();

  const { mutate: sendOtp } = useMutation(sentOtpFn, {
    onSuccess: (response) => {
      setLoading(false);
      setShowOtpInput(true);
      toast.success("OTP sent successfully!");
      setCanResend(false);
      setTimeout(() => setCanResend(true), 20000);
    },
    onError: (error) => {
      setLoading(false);
      toast.error("Failed to send OTP. Please try again.");
      console.log(error);
    }
  });

  const { mutate: verifyOtp } = useMutation(verifyOTPFn, {
    onSuccess: (response) => {
      setLoading(false);
      if (response?.data?.response_code === 200 && (response?.data?.user_type === "Engineer" || response?.data?.user_type === "Admin")) {
        localStorage.setItem("sewaup", response?.data?.token_key);
        localStorage.setItem("user_type", response?.data?.user_type)
        setIsTokenExist(response?.data?.token_key);
        setUserType(response?.data?.user_type);
        toast.success("OTP Verified Successfully!");
      } else {
        toast.error("Your account is deactivated.")
      }
      setTimeout(() => {
        navigate("/");
      }, 2000)
    },
    onError: (error) => {
      setLoading(false);
      toast.error("Invalid OTP. Please try again.");
      console.log(error);
    }
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailSubmit = () => {
    if (validateEmail(email)) {
      setEmailError("");
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      sendOtp(formData);
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      if (otp[index]) {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        newOtp[index - 1] = "";
        setOtp(newOtp);
        inputsRef.current[index - 1].focus();
      }
    }
  };

  const handleOtpSubmit = () => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      setOtpError("");
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("otp", otpValue);
      verifyOtp(formData);
    } else {
      setOtpError("Please enter the full 6-digit OTP.");
    }
  };

  const handleResend = () => {
    if (canResend) {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", email);
      sendOtp(formData);
    }
  };

  return (
    <div>
      <div className='bg-white flex justify-center text-black h-[70vh] pt-[3%] px-4'>
        {!showOtpInput ? (
          <div className='max-w-[800px] border w-full self-start min-h-[400px] shadow-xl flex flex-col gap-4 justify-center items-center text-center'>
            <img src={logo} alt='' className=' w-[10rem] object-cover mx-auto'></img>
            <p className='font-bold text-xl'>Log in or sign up to Cewaup</p>
            <input
              type="email"
              required
              placeholder='Enter your email'
              className='mx-auto border w-[300px] outline-none py-2 px-4'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className='text-red-600'>{emailError}</p>}
            <button
              className='bg-blue-900 text-white py-2 px-8 rounded-full w-[300px] disabled:bg-gray-400'
              onClick={handleEmailSubmit}
              disabled={loading}
            >
              {loading ? 'LOADING...' : 'CONTINUE'}
            </button>
          </div>
        ) : (
          <div className='max-w-[800px] w-full min-h-[500px] shadow-xl flex flex-col gap-4 justify-center items-center text-center self-start'>
            <img src={logo} alt='' className=' w-[10rem] object-cover mx-auto' />
            <p className='font-bold text-xl'>Enter OTP</p>
            <div className='h-10 grid grid-cols-6 gap-4 w-[350px]'>
              {otp.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  value={value}
                  required
                  className='border border-gray-300 text-center'
                  maxLength={1}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  ref={(el) => inputsRef.current[index] = el}
                />
              ))}
            </div>
            {otpError && <p className='text-red-600'>{otpError}</p>}
            <p>Didn't Receive Code? <span className={`text-orange-600 cursor-pointer ${!canResend ? 'text-gray-400 cursor-not-allowed' : ''}`} onClick={handleResend}>Resend</span></p>
            <button
              className='bg-blue-900 text-white py-2 px-8 rounded-full w-[300px] disabled:bg-gray-400'
              onClick={handleOtpSubmit}
              disabled={loading}
            >
              {loading ? 'LOADING...' : 'CONTINUE'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default LogIn;
