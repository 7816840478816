import { BrandingWatermark, BrowseGallery, BrowseGalleryTwoTone, ContactSupport, Dashboard, Engineering, Event, PaymentsOutlined, Slideshow } from "@mui/icons-material";
import BookIcon from "@mui/icons-material/Book";
import CategoryIcon from "@mui/icons-material/Category";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PaymentIcon from '@mui/icons-material/Payment';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Groups2Icon from '@mui/icons-material/Groups2';


export const SidebarNav = [
    {
        id: 1,
        navLink: "/profile",
        navItem: "Profile",
        navIcon: <BookIcon className="text-blue-800" />,
    },
    {
        id: 2,
        navLink: "/payment-history",
        navItem: "Payment History",
        navIcon: <PaymentIcon className="text-blue-800" />,
    },
    // {
    //     id: 3,
    //     navLink: "/engineer-donation",
    //     navItem: "Donetion History",
    //     navIcon: <MonetizationOnIcon className="text-orange-500" />,
    // }
]

export const adminSidebarNav = [
    {
        id: 9,
        navLink: "/dashboard",
        navItem: "dashboard",
        navIcon: <Dashboard className="text-lime-500" />,
    },
    {
        id: 9,
        navLink: "/engineers",
        navItem: "Engineers",
        navIcon: <Engineering className="text-pink-500" />,
    },
    {
        id: 14,
        navLink: "/our-members",
        navItem: "Our Members",
        navIcon: <Groups2Icon className="text-gray-800" />,
    },
    {
        id: 12,
        navLink: "/create-certificate-plan",
        navItem: "Create Certificate Plan",
        navIcon: <GppGoodIcon className="text-purple-600" />,
    },
    {
        id: 10,
        navLink: "/create-payment-link",
        navItem: "Create Payment Link",
        navIcon: <PaymentsOutlined className="text-amber-500" />,
    },
    {
        id: 13,
        navLink: "/donation",
        navItem: "Donation",
        navIcon: <MonetizationOnIcon className="text-orange-500" />,
    },
    {
        id: 1,
        navLink: "/blogs",
        navItem: "Blogs",
        navIcon: <BookIcon className="text-blue-800" />,
    },
    {
        id: 2,
        navLink: "/category",
        navItem: "Category",
        navIcon: <CategoryIcon className="text-amber-700" />,
    },
    {
        id: 3,
        navLink: "/sub-category",
        navItem: "Sub Category",
        navIcon: <CategoryIcon className="text-cyan-500" />,
    },
    {
        id: 4,
        navLink: "/news",
        navItem: "News",
        navIcon: <NewspaperIcon className="text-green-500" />,
    },
    {
        id: 12,
        navLink: "/event",
        navItem: "Event",
        navIcon: <Event className="text-gray-800" />,
    },
    {
        id: 5,
        navLink: "/gallery",
        navItem: "Gallery",
        navIcon: <BrowseGallery className="text-orange-500" />,
    },
    {
        id: 6,
        navLink: "/gallery-image",
        navItem: "Gallery Image",
        navIcon: <BrowseGalleryTwoTone className="text-yellow-500" />,
    },
    {
        id: 7,
        navLink: "/slider",
        navItem: "Slider",
        navIcon: <Slideshow className="text-purple-500" />,
    },
    {
        id: 8,
        navLink: "/banner",
        navItem: "Banner",
        navIcon: <BrandingWatermark className="text-cyan-500" />,
    },
    {
        id: 11,
        navLink: "/contact-us",
        navItem: "Contact Us",
        navIcon: <ContactSupport className="text-gray-800" />,
    },

]

export default SidebarNav;