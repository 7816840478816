import { Button } from '@mui/material';
import { imageFormat } from 'Configs/axios';
import React from 'react'
import { useMutation, useQuery } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteNewsByIdFn, getNewsFn } from 'Services/Admin/News';

const News = () => {
    const navigate = useNavigate();
    const { data: getNewsResponse, refetch } = useQuery({
        queryFn: getNewsFn,
        queryKey: ["get-news-fn"]
    })

    const { mutate: deleteNewsById } = useMutation(deleteNewsByIdFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("New deleted successfully.");
                refetch();
            }
        }
    })

    const deleteHandle = (id) => {
        const formData = new FormData();
        formData.append("news_id", id);
        deleteNewsById(formData);
    }

    return (
        <>
            <p className="my-3 text-5xl text-center text-blue-900 underline">News</p>
            <div className="flex justify-between item-center w-[100%]">
                <NavLink to="/news/add">
                    <div className="text-xl font-bold !text-gray-600 p-3">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {getNewsResponse?.data?.data?.map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            className="flex justify-between p-4 m-3 border border-gray-300 rounded drop-shadow-2xl w-full"
                        >
                            <div className="flex flex-col items-center justify-center">
                                <p className="px-4 py-4 text-2xl text-black">
                                    {item.title}
                                </p>
                            </div>
                            <div className="flex-row justify-center mt-9">
                                <div className="flex flex-col items-center justify-center w-full">
                                    <img
                                        src={`${imageFormat(item.image)}`}
                                        key={item.image}
                                        alt="ab"
                                        className="h-44 w-44"
                                    />
                                </div>
                                <div className="flex items-center justify-center w-full px-5">
                                    <div className="px-5 mt-8 ">
                                        <Button
                                            onClick={() => navigate(`/news/update`, { state: item.id })}
                                            variant="outlined"
                                        >
                                            Update
                                        </Button>
                                    </div>

                                    <div className="px-5 mt-8 ">
                                        <Button
                                            onClick={() => deleteHandle(item.id)}
                                            variant="outlined"
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default News;