import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getDashboardFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.dashboard);
        return response;
    } catch (error) {
        throw error;
    }
}