import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getNewsByIdFn, patchNewsFn, postNewsFn } from "Services/Admin/News";

const AddNews = () => {
    const { state } = useLocation();
    const { status } = useParams();
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    const { mutate: postNews } = useMutation(postNewsFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("News Created.");
                navigate("/news");
            }
        }
    });

    const { mutate: patchNews } = useMutation(patchNewsFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("News updated successfully");
                navigate("/news");
            }
        }
    });

    const { data: getNewsById } = useQuery({
        queryFn: () => getNewsByIdFn(state),
        queryKey: ["get-news-by-id", state],
        enabled: !!state,
    });

    const news = getNewsById?.data?.data;

    const formik = useFormik({
        initialValues: {
            title: news?.title || "",
            image: "",
            content: news?.content || "",
            location: news?.location || "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            Object.keys(values).forEach(key => reqbody.append(key, values[key]));

            if (status === "update") {
                if (!image) reqbody.delete("image");
                reqbody.append("news_id", state);
                patchNews(reqbody);
            } else {
                if (!image) {
                    return toast.error("Image is required.");
                }
                postNews(reqbody);
            }
        }
    });

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);
        formik.setFieldValue("image", event.target.files[0]);
    };

    return (
        <div className="flex flex-col w-full ">
            <div className="px-5 mt-5 bg-white shadow-lg">
                <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
                    {status === "update" ? "Update News" : "Add News"}
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-2 gap-5">
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Title</p>
                                <input
                                    placeholder="Enter title"
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">Location</p>
                                <input
                                    placeholder="Enter location"
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3 ">
                            <div>
                                <p className="p-1 text-sm font-semibold">File:</p>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full pl-3 ">
                        <div>
                            <p className="p-1 text-sm font-semibold">Content:</p>
                            <ReactQuill
                                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                                value={formik.values.content}
                                onChange={(value) => formik.setFieldValue('content', value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
                        <Button className="!w-32" type="submit" variant="contained">
                            {status === "update" ? "Update" : "Add"}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddNews;
