import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getBannerByIdFn, patchBannerFn, postBannerFn } from "Services/Admin/Banner";
import { getSliderByIdFn, patchSliderFn, postSliderFn } from "Services/Admin/Slider";

const AddBanner = () => {
    const { state } = useLocation();
    const { state: status } = useParams();
    const navigate = useNavigate();

    const { mutate: postBanner } = useMutation(postBannerFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("New Banner Created.");
                navigate("/banner");
            }
        }
    });

    const { mutate: patchBanner } = useMutation(patchBannerFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("Banner updated successfully");
                navigate("/banner");
            }
        }
    });

    const { data: getBannerById } = useQuery({
        queryFn: () => getBannerByIdFn(state),
        queryKey: ["get-banner-by-id", state],
        enabled: !!state,
    });

    const slider = getBannerById?.data?.data;
    const formik = useFormik({
        initialValues: {
            banner_section: slider?.banner_section || "",
            description: slider?.description || "",
            link: slider?.link || "",
            image: null,
            offer_tag: slider?.offer_tag || ""
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("link", values.link);
            reqbody.append("banner_section", values.banner_section);
            reqbody.append("description", values.description);
            reqbody.append("offer_tag", values.offer_tag);
            if (values.image) {
                reqbody.append("image", values.image);
            }

            if (status === "update") {
                reqbody.append("banner_id", state);
                patchBanner(reqbody);
            } else {
                if (!values.image) {
                    return toast.error("Image is required.");
                }
                postBanner(reqbody);
            }
        },
    });

    const handleImageChange = (event) => {
        formik.setFieldValue("image", event.target.files[0]);
    };

    return (
        <div className="flex flex-col w-full">
            <div className="px-5 mt-5 bg-white shadow-lg">
                <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
                    {status === "update" ? "Update Banner" : "Add Banner"}
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-2 gap-5">
                        <div className="w-full pl-3">
                            <p className="p-1 text-sm font-semibold">Banner Section</p>
                            <select
                                className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                name="banner_section"
                                value={formik.values.banner_section}
                                onChange={formik.handleChange}
                            >
                                <option></option>
                                <option value="top_brand_banner">top_brand_banner</option>
                                <option value="today_deals_banner">today_deals_banner</option>
                                <option value="recently_banner">recently_banner</option>
                                <option value="latest_deals_banner">latest_deals_banner</option>
                                <option value="top_rated_banner">top_rated_banner</option>
                                <option value="footer_banner">footer_banner</option>
                                <option value="recommended_banner">recommended_banner</option>
                                <option value="top_web_stories">top_web_stories</option>
                            </select>
                        </div>
                        <div className="w-full pl-3">
                            <div>
                                <p className="p-1 text-sm font-semibold">Link</p>
                                <input
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="link"
                                    value={formik.values.link}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3">
                            <div>
                                <p className="p-1 text-sm font-semibold">Offer Tag</p>
                                <input
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="offer_tag"
                                    value={formik.values.offer_tag}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3">
                            <div>
                                <p className="p-1 text-sm font-semibold">File:</p>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full pl-3">
                        <div>
                            <p className="p-1 text-sm font-semibold">Description:</p>
                            <ReactQuill
                                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                                value={formik.values.description}
                                onChange={(value) => formik.setFieldValue('description', value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full pl-3 my-5 mt-5">
                        <Button className="!w-32" type="submit" variant="contained">
                            {status === "update" ? "Update" : "Add"}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddBanner;
