import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';

const RemarkModal = ({ open, onClose, onSubmit }) => {
    const [remark, setRemark] = useState("");

    const handleSubmit = () => {
        onSubmit(remark);
        setRemark(""); // Clear the remark after submission
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2>Enter Remark</h2>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RemarkModal;
