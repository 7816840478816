import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getSubCategoriesFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.sub_category_api);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSubCategoryByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.sub_category_api}?subcategory_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteSubCategoryByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.sub_category_api}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postSubCategoriesFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.sub_category_api, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchSubCategoriesFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.sub_category_api, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}