import { Call, Mail, SwitchAccount } from '@mui/icons-material'
import { Button } from '@mui/material'
import { pink } from '@mui/material/colors'
import background from 'assets/background.jpg'
import useDonationContext from 'context/useDonationContext'

const ContactInfo = () => {
  const { handleModal } = useDonationContext();
  return (
    <div>
      <div className='relative '>
        <img src='https://www.sresindia.org/images/h2factsbg.jpg' alt="" className=' lg:h-[600px] md:h-[950px] h-[900px]' />
        <div className="absolute md:top-10 top-5 lg:left-[20%] lg:transform  lg:right-[20%]">
          <div className="flex flex-col gap-10 p-4 bg-opacity-50 rounded lg:flex-row">
            <div className="flex flex-col lg:w-[100%] lg:justify-center w-[100%] ">
              {/* <p className="p-4 text-2xl font-bold text-black md:text-3xl">IMPORTANT LINKS</p> */}
              <div className='bg-white flex lg:justify-between border-4 border-pink-200 shadow-xl lg:p-4 mt-8 h-[350px] relative'>
                <img src={background} alt="" className='absolute top-0 left-0 z-0 w-full h-full' />
                <div className='relative flex flex-col items-center justify-center gap-5 p-4 z-1'>
                  {/* <div className='flex gap-3 text-center'>
                    <img src="https://www.sresindia.org/images/excomm-icon2.png" alt='' className='rounded-full bg-blue-950'></img>
                    <p className='pt-2 text-xl font-bold'>NIFR</p>
                  </div>
                  <div className='flex gap-3 text-center'>
                    <img src="https://www.sresindia.org/images/excomm-icon2.png" alt='' className='rounded-full bg-blue-950'></img>
                    <p className='pt-2 text-xl font-bold'>INTUC</p>
                  </div>
                  <div className='flex gap-3 text-center'>
                    <img src="https://www.sresindia.org/images/excomm-icon2.png" alt='' className='rounded-full bg-blue-950'></img>
                    <p className='pt-2 text-xl font-bold'> ITF</p>
                  </div>
                  <div className='flex gap-3 text-center'>
                    <img src="https://www.sresindia.org/images/excomm-icon2.png" alt='' className='w-12 h-12 rounded-full bg-blue-950'></img>
                    <p className='pt-2 text-xl font-bold'>NFIR Affiliate Links</p>
                  </div>

                  <div className='flex gap-3 text-center'>
                    <img src="https://www.sresindia.org/images/excomm-icon2.png" alt='' className='rounded-full bg-blue-950'></img>
                    <p className='pt-2 text-xl font-bold'>Get PNR Status</p>
                  </div> */}
                  <h4 className='text-2xl font-bold text-center'>Support CeawaUp</h4>
                  <p className='text-center'>Help us build essential infrastructure in rural areas. Your donation makes a difference.</p>
                  <Button
                    className='mx-auto'
                    variant="contained"
                    onClick={handleModal}
                    sx={{
                      background: pink[500],
                      color: 'white',
                      "&:hover": {
                        background: "blue"
                      }
                    }}
                  >
                    Donate Today
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:w-[100%] lg:justify-center w-[100%]">
              <p className="p-2 text-2xl font-bold text-black md:text-3xl lg:p-3">CONTACT INFO</p>
              <div className='flex gap-1 p-3 mt-3 bg-white border-2 border-gray-400 '>
                <SwitchAccount className='text-[#d94148]' fontSize='large' />
                <p className='text-base font-bold text-gray-600'> C block Indira nagar Lucknow</p>
              </div>
              {/* <div className='grid grid-cols-2 gap-5 mt-3'>
                <div className='flex flex-col p-3 bg-white border-2 border-gray-400'>
                  <Call className='text-[#d94148]' fontSize='large' />
                  <p className='text-lg font-bold text-gray-800'>+91-08881111</p>
                  <p className='text-lg text-[#d94148] font-semibold'>Phone</p>
                </div>
                <div className='flex flex-col p-3 bg-white border-2 border-gray-400 '>
                  <Call className='text-[#d94148]' fontSize='large' />
                  <p className='text-lg font-bold text-gray-800'>044-26701425</p>
                  <p className='text-lg text-[#d94148] font-semibold'>Phone</p>
                </div>

              </div> */}
              <div className='flex flex-col p-3 mt-3 bg-white border-2 border-gray-400 lg:p-2'>
                <div className='flex gap-2'>
                  <Call className='text-[#d94148]' fontSize='large' />
                  {/*  <p className='text-xl font-bold text-gray-600'> +91-9039131372
                </p> */}


                  <a href="tel:+919039131372" className='text-base font-bold text-gray-600'>+91-9039131372</a>
                </div>
              </div>
              <div className='flex flex-col p-3 mt-3 bg-white border-2 border-gray-400 lg:p-2'>
                <div className='flex gap-2'>

                  <Mail className='text-[#d94148]' fontSize='large' />

                  <p className='text-base font-bold text-gray-600'>ceawaup@gmail.com
                  </p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContactInfo
