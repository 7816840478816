import { Divider } from '@mui/material';
import { publicAxiosInstance } from 'Configs/axios';
import { API_URLS } from 'Configs/urls';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NewsEvents = () => {
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const sliderFn = async () => {

    const response = await publicAxiosInstance.get(API_URLS.new_api)
    setData(response.data.data);
  };

  const eventFn = async () => {
    try {
      const response = await publicAxiosInstance.get(API_URLS.event_api);
      setEventData(response?.data?.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    sliderFn();
    eventFn();
  }, []);

  return (
    <div className="flex flex-col justify-center gap-10 p-2 text-black bg-white md:flex-row">
      <div className="flex flex-col bg-white pt-14 md:pt-14 lg:pt-0">
        <div className="bg-white md:w-[90%] w-[100%] gap-5  ">
          <div className="flex p-2 bg-blue-800">
            <p className="text-white">Description</p>
          </div>
          <div className="flex flex-col gap-3 border border-gray-500">
            {data && data.map(data => (
              <div key={data.id}>
                <div className="flex gap-5 p-2">
                  <p className="w-[80%]">{data.title}</p>
                  <div className="w-[20%] flex justify-center ">
                    <button className="items-center bg-white ">
                      <Link to={`/news/${data.id}`} className="p-1 bg-red-500 rounded-3xl">Preview</Link>
                    </button>
                  </div>
                </div>
                <Divider className="bg-gray-500 border " />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="bg-white h-[10vh] lg:w-[50rem] shadow-2xl flex justify-center items-center">
          <div className=" grid grid-cols-7  border w-[13rem] h-[3vh] text-center  cursor-pointer">
            <ChevronLeft className="hover:bg-red-500 hover:text-white" />
            <p className="border hover:bg-red-500 hover:text-white ">1</p>
            <p className="border hover:bg-red-500 hover:text-white ">2</p>

            <p className="border hover:bg-red-500 hover:text-white">3</p>
            <p className="border hover:bg-red-500 hover:text-white">4</p>
            <p className="border hover:bg-red-500 hover:text-white">5</p>
            <ChevronRight className=" hover:bg-red-500 hover:text-white" />
          </div>
        </div> */}
      </div>
      <div className="flex flex-col justify-center gap-5 lg:pt-0 md:pt-10">
        <div className="flex flex-col p-4 mb-5 bg-white shadow-2xl">
          <p className="text-xl text-[#d94148] font-bold p-4">Meeting & Events </p>
          <Divider className="bg-gray-200 " />
          <div className="flex flex-col gap-2 text-base font-medium">
            {eventData.map(e => <Link to={`/event/${e.id}`} className='cursor-pointer hover:underline' key={e.title}>{e.title}</Link>)}
          </div>
        </div>
        {/* <div className="flex flex-col p-4 mb-5 bg-white shadow-2xl">
          <p className="text-xl text-[#d94148] font-bold p-4">SRES Circulars </p>
          <Divider className="bg-gray-200 " />
          <div className="flex flex-col gap-2 text-xl font-bold">
            <p> Engineering</p>
            <p>Traffic</p>
            <p> Commercial </p>
            <p> Ministerial /Accounts / IT / Hindi etc </p>
            <p> Medical </p>
            <p> Electrical </p>
            <p> Mechanical </p>
            <p> Technician & Technical Supervisors </p>
            <p> Running </p>
            <p>Signal Department </p>
            <p> Secret Ballot Election </p>
            <p>Common </p>
          </div>
        </div>
        <div className="flex flex-col justify-center p-4 mb-5 bg-white shadow-2xl">
          <p className="text-xl text-[#d94148] font-bold p-4">Order Board </p>
          <Divider className="bg-gray-200 " />
          <div className="flex flex-col gap-2 text-xl font-bold">
            <p> Engineering</p>
            <p>Traffic</p>
          </div>
        </div>
        <div className="bg-white p-3 lg:w-[20rem] w-[25rem] mb-5 shadow-2xl flex flex-col">
          <p className="text-xl text-[#d94148] font-bold p-4">Latest News</p>
          <Divider className="bg-gray-200 " />
          <div className="flex flex-col gap-2 p-4 text-xl font-bold">
            <p>
              29th National Convention of NFIR to be held on 17th & 18th September 2019 at Ujjain
              Meeting on Joint Committee on MACPS Anomalies Meeting of Cadre Restructuring Committee
            </p>
            <p>Traffic</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default NewsEvents
