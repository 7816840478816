import { privateAxiosIntance } from "Configs/axios";
import { protectedRoutes } from "Configs/urls";

export const getBannerFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.banner);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getBannerByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.banner}?banner_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBannerByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.banner}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postBannerFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.banner, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchBannerFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.banner, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}