import { Box, Modal, Typography, Button, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDonationContext from 'context/useDonationContext';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import { giveDonationFn } from 'Services/Donation';
import logo from 'assets/logo-transparent.png';
import { publicAxiosInstance } from 'Configs/axios';
import { API_URLS, RAZORPAY_KEY } from 'Configs/urls';

import PaymentSuccess from '../PaymentSuccess';
import LoopIcon from "@mui/icons-material/Loop";
import { toast } from 'react-toastify';

const Donation = () => {
    const { openModal, handleModal } = useDonationContext();
    const [orderId, setOrderId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentSuccess = async (response) => {
        handleModal();
        setIsLoading(false);

        try {
            let bodyData = new FormData();
            bodyData.append("response", JSON.stringify(response));
            await publicAxiosInstance.post(API_URLS.payment_success, bodyData)
                .then((res) => {
                    console.log("Payment successful!");
                    setOrderId(response.razorpay_order_id);
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Payment verification failed.");
                });
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong during payment processing.");
        }
    };

    const showRazorpay = async (data) => {
        setIsLoading(true);

        const loadScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = "https://checkout.razorpay.com/v1/checkout.js";
                script.onload = () => resolve(true);
                script.onerror = () => reject(new Error("Razorpay SDK failed to load"));
                document.body.appendChild(script);
            });
        };

        const scriptLoaded = await loadScript();
        if (!scriptLoaded) {
            toast.error("Razorpay SDK failed to load.");
            setIsLoading(false);
            return;
        }

        const options = {
            key: RAZORPAY_KEY,
            amount: data.price,
            currency: "INR",
            name: "CEAWAUP",
            description: "Test transaction",
            image: logo,
            order_id: data?.payment_no,
            handler: function (response) {
                handlePaymentSuccess(response);
            },
            prefill: {
                name: data.name,
                email: data.email,
                contact: data.mobile,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const { mutate } = useMutation(giveDonationFn, {
        onSuccess: (response) => {
            const data = response?.data?.data;
            showRazorpay(data);
        },
        onError: (error) => {
            console.error(error);
            toast.error("Failed to initiate donation.");
            setIsLoading(false);
        }
    });

    useEffect(() => {
        if (orderId)
            setTimeout(() => {
                setOrderId(null);
            }, 3000);
    }, [orderId]);

    return (
        <>
            {isLoading && (
                <div className="fixed flex items-center justify-center w-full h-screen">
                    <LoopIcon className="animate-spin !text-7xl" color="primary" />
                </div>
            )}
            {orderId && <PaymentSuccess orderId={orderId} />}
            <Modal
                open={openModal}
                onClose={handleModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(3px)",
                    padding: { xs: '10px', md: '20px' }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        boxShadow: 24,
                        maxWidth: { xs: '100%', sm: '600px', md: '700px' },
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        p: 0,
                        width: "100%",
                        position: 'relative',
                        outline: 'none',
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage: 'url(https://careerads.s3.ap-south-1.amazonaws.com/ceawaup+about/logo-transparent.070a1c89b04adc2015f9.png)',
                            backgroundSize: '',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            borderRadius: { xs: '12px 12px 0 0', md: '12px 0 0 12px' },
                            width: { xs: "100%", md: "50%" },
                            height: { xs: '300px', md: 'auto' },
                        }}

                    />

                    <Box
                        sx={{
                            flex: 1,
                            padding: { xs: 2, md: 4 },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <IconButton
                            onClick={handleModal}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: '999px',
                                background: 'white'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography id="modal-title" variant="h6" component="h2" mb={2} textAlign={"center"}>
                            Make a Donation
                        </Typography>

                        <Formik
                            initialValues={{
                                name: "",
                                mobile: "",
                                email: "",
                                price: "",
                            }}
                            onSubmit={(values) => {
                                const formData = new FormData();
                                Object.keys(values).forEach(key => formData.append(key, values[key]));
                                mutate(formData);
                            }}
                        >
                            {props => (
                                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <TextField
                                        label="Name"
                                        variant="outlined"
                                        name='name'
                                        fullWidth
                                        required
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    <TextField
                                        label="Mobile"
                                        variant="outlined"
                                        name='mobile'
                                        fullWidth
                                        required
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    <TextField
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        name='email'
                                        fullWidth
                                        required
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />
                                    <TextField
                                        label="Donation Amount"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        name="price"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={props.handleSubmit}
                                        sx={{ borderRadius: '8px', padding: '10px 20px', mt: 2 }}
                                    >
                                        Donate
                                    </Button>
                                </Box>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Donation;
